import React from "react";
import PropTypes from "prop-types";
import Logo from "./logo";
import ArticleUnderlined from "./articleWithUnderlinedHeader";
import "./headerWithLogo.css";

const HeaderWithLogo = ({ header, headerSize, text, webp, png, bar, size }) => {
  return (
    <article className={"container-split"}>
      <ArticleUnderlined headerSize={headerSize} header={header} text={text} />
      <Logo webp={webp} png={png} alt={bar} size={size} />
    </article>
  );
};

export default HeaderWithLogo;

HeaderWithLogo.propTypes = {
  header: PropTypes.string,
  headerSize: PropTypes.string,
  text: PropTypes.string,
  webp: PropTypes.string,
  png: PropTypes.string,
  bar: PropTypes.string,
  size: PropTypes.string,
};
