import React, { useState, useRef } from "react";
import Menu from "./menu";
import Quiz from "./quiz";
import Summary from "../quizSummary";
import getRandomWords from "../../utils/wordsList";
import useCheckPlaceAccess from "../hooks/useCheckPlaceAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import { navbarBgChanged } from "../../store/game";
import {
  eventCounterIncremented,
  expAdded,
  creditsAdded,
} from "../../store/auth";
import { useDispatch } from "react-redux";
import ModalWindow from "../universal/modal";
import "./controller.css";
import "./quiz.css";

const Controller = () => {
  useCheckPlaceAccess("quiz");
  useChangeGameStatus();

  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [modalTrigger, setModalTrigger] = useState(false);

  const toggleModal = () => {
    setModalTrigger(!modalTrigger);
  };

  let quizLength = useRef(0);
  let wordsList = useRef([]);
  let currentIndex = useRef(0);
  let currentWord = useRef("");
  let counter = useRef(1);
  let quizPoints = useRef(0);
  let wordsWithAnswers = useRef({});

  const handleShowMenu = () => {
    setShowMenu((showMenu) => !showMenu);
  };

  const handleShowQuiz = () => {
    setShowQuiz((showQuiz) => !showQuiz);
  };
  const handleShowSummary = () => {
    setShowSummary((showSummary) => !showSummary);
  };

  const handleSetUpQuiz = (num) => {
    const inputValue = num;
    quizLength.current = inputValue;
    makeWordsList(quizLength.current);
  };

  const handleWordsWithAnswers = (word, definition) => {
    wordsWithAnswers.current[word] = definition;
  };

  const makeWordsList = (quizLength) => {
    const list = getRandomWords(quizLength);
    const arr = [];
    for (let word of list) {
      arr.push({
        id: list.indexOf(word),
        word: word,
        guessed: false,
        skipped: false,
        points: 0,
      });
    }
    wordsList.current = arr;
    currentWord.current = wordsList.current[currentIndex.current].word;
    handleShowQuiz();
  };

  const handleSubmitAnswer = (data) => {
    const answer = data.toLowerCase();
    inputValidation(answer);
  };

  const inputValidation = (answer) => {
    if (answer === currentWord.current) {
      wordsList.current[currentIndex.current].guessed = true;
      wordsList.current[currentIndex.current].points = 100;
      currentIndex.current += 1;
      dispatch(eventCounterIncremented("wordsRevealed"));
      counter.current += 1;
      if (quizLength.current >= counter.current) {
        currentWord.current = wordsList.current[currentIndex.current].word;
      }
      if (quizLength.current < counter.current) {
        quizSummary();
      }
      // forces the Quiz component to re-render with a new word by changing it's uniqe key
      setKey(Math.random());
    } else {
      toggleModal();
    }
  };

  const handleSkipDefinition = () => {
    wordsList.current[currentIndex.current].skipped = true;
    currentIndex.current += 1;
    counter.current += 1;
    if (quizLength.current >= counter.current) {
      currentWord.current = wordsList.current[currentIndex.current].word;
    }
    if (quizLength.current < counter.current) {
      quizSummary();
    }
    setKey(Math.random());
  };

  const quizSummary = () => {
    const rawPoints = wordsList.current.reduce((total, current) => {
      return total + current.points;
    }, 0);
    quizPoints.current = rawPoints;
    dispatch(creditsAdded(quizPoints.current / 2));
    dispatch(expAdded(quizPoints.current));
    counter.current = 1;
    currentIndex.current = 0;
    currentWord.current = "";
    handleShowSummary();
    handleShowQuiz();
  };

  return (
    <main
      className="main-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <section id="quiz">
        {showMenu && (
          <Menu
            showMenu={handleShowMenu}
            showQuiz={handleShowQuiz}
            setUpQuiz={handleSetUpQuiz}
          />
        )}
        {showQuiz && (
          <Quiz
            showMenu={handleShowMenu}
            showQuiz={handleShowQuiz}
            count={counter.current}
            len={quizLength.current}
            key={key}
            word={currentWord.current}
            submitAnswer={handleSubmitAnswer}
            skipDefinition={handleSkipDefinition}
            fillTheWord={inputValidation}
            addAnswerToWord={handleWordsWithAnswers}
          />
        )}
        {showSummary && (
          <Summary
            wordsWithDefs={wordsWithAnswers.current}
            summary={quizPoints.current}
            showQuiz={handleShowQuiz}
            showMenu={handleShowMenu}
            showSummary={handleShowSummary}
          />
        )}
        <ModalWindow
          isOpen={modalTrigger}
          onRequestClose={toggleModal}
          dataTestid={"quizCloseModalButton"}
          contentLabel="Quiz answer"
          content={<span>Try again</span>}
        />
      </section>
    </main>
  );
};

export default Controller;
