import React from "react";
import PropTypes from "prop-types";
import "./header.css";

const Header = ({ header, headerSize, underline }) => {
  const renderHeader = () => {
    if (headerSize === "h2") {
      return <h2>{header}</h2>;
    } else if (headerSize === "h3") {
      return <h3>{header}</h3>;
    } else if (headerSize === "h4") {
      return <h4>{header}</h4>;
    } else if (headerSize === "h5") {
      return <h5>{header}</h5>;
    } else {
      return <h1>{header}</h1>;
    }
  };

  const toggleStyle = () => {
    return underline ? "places-header" : "";
  };

  return (
    <header className={toggleStyle()}>
      {renderHeader()}
      {underline && <hr className="underline-places" />}
    </header>
  );
};

export default Header;

Header.propTypes = {
  header: PropTypes.string,
  headerSize: PropTypes.string,
  underline: PropTypes.bool,
};
