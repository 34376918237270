import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import Emitter from "../../utils/emitter";
import { useDispatch, useSelector } from "react-redux";
import {
  movementPointsAdded,
  turnCounterIncremented,
  trophyCollected,
} from "../../store/auth";
import { taskFinished } from "../../store/auth";
import { notify } from "../../store/utils";
import { logout } from "../../store/auth";
import decode from "jwt-decode";

const Timer = (props) => {
  const dispatch = useDispatch();
  const reset = () => {
    setTime([props.mins, props.secs]);
    dispatch(turnCounterIncremented());
    dispatch(movementPointsAdded(5));
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
    return <Redirect to={"/auth"} />;
  }, [dispatch]);

  const tick = () => {
    if (mins === 0 && secs === 0) {
      const user = JSON.parse(localStorage.getItem("rocket-english-profile"));
      const token = user?.credential;
      if (token) {
        const decodedToken = decode(token);
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          handleLogout();
        } else {
          reset();
        }
      } else {
        handleLogout();
      }
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  useEffect(() => {
    localStorage.setItem("minutes", mins);
    localStorage.setItem("seconds", secs);
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const {
    currentPlanet,
    taskQueue,
    turnNumber,
    trophiesCollected,
    planets,
    inventory,
    eventsHappened,
  } = useSelector((state) => state.user.auth.currentUser.progress);
  const { progress } = useSelector((state) => state.user.auth.currentUser);
  const { trophies } = useSelector((state) => state.environment?.trophy);

  const setTimerFromLocalStorage = () => {
    const storedMins = localStorage.getItem("minutes");
    const storedSecs = localStorage.getItem("seconds");
    if (storedMins === null || storedSecs === null) {
      return [props.mins, props.secs];
    } else {
      return [
        Number(localStorage.getItem("minutes")),
        Number(localStorage.getItem("seconds")),
      ];
    }
  };

  const handleNotification = (trophy) => {
    return (
      <div className="multi-line-toast">
        <p>
          <i className="fas fa-trophy"></i> Trophy Collected:
        </p>
        <p>{trophy.name}</p>
        <p>+{trophy.reward} exp</p>
      </div>
    );
  };

  const [[mins, secs], setTime] = useState(setTimerFromLocalStorage());

  const checkTaskQueue = useCallback(() => {
    if (taskQueue.length !== 0) {
      const currentTask = taskQueue.find(
        (task) => task.startingTurnNumber === turnNumber
      );
      if (currentTask) {
        Emitter.emit("START_AN_EXPEDITION", [currentTask, currentPlanet]);
        dispatch(taskFinished(currentTask.id));
        props.closeMenuForExpedition();
      }
    }
  }, [currentPlanet, dispatch, props, taskQueue, turnNumber]);

  useEffect(() => {
    checkTaskQueue();
  }, [checkTaskQueue, turnNumber]);

  const checkConditions = useCallback(() => {
    const uncollected = trophies.filter(
      (trophy) => !trophiesCollected.includes(trophy._id)
    );

    if (uncollected.length !== 0) {
      loop1: for (let trophy of uncollected) {
        if (trophy.type === "user") {
          for (let [condition, value] of Object.entries(trophy.conditions)) {
            if (value > progress[condition]) {
              continue loop1;
            }
          }
          dispatch(trophyCollected({ id: trophy._id, reward: trophy.reward }));
          notify(handleNotification(trophy));
        } else if (trophy.type === "place") {
          for (let value of Object.values(trophy.conditions)) {
            if (!planets.available.includes(value)) {
              continue loop1;
            }
          }
          dispatch(trophyCollected({ id: trophy._id, reward: trophy.reward }));
          notify(handleNotification(trophy));
        } else if (trophy.type === "inventory") {
          for (let [condition, value] of Object.entries(trophy.conditions)) {
            if (value > inventory[condition]) {
              continue loop1;
            }
          }
          dispatch(trophyCollected({ id: trophy._id, reward: trophy.reward }));
          notify(handleNotification(trophy));
        } else if (trophy.type === "event") {
          for (let [condition, value] of Object.entries(trophy.conditions)) {
            if (value > eventsHappened[condition]) {
              continue loop1;
            }
          }
          dispatch(trophyCollected({ id: trophy._id, reward: trophy.reward }));
          notify(handleNotification(trophy));
        }
      }
    }
  }, [
    dispatch,
    eventsHappened,
    inventory,
    planets.available,
    progress,
    trophies,
    trophiesCollected,
  ]);

  useEffect(() => {
    checkConditions();
  }, [checkConditions]);

  return (
    <span>
      {`${mins.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`}
    </span>
  );
};

export default Timer;

Timer.propTypes = {
  currentPlanet: PropTypes.string,
  taskQueue: PropTypes.array,
  turnNumber: PropTypes.number,
  trophiesCollected: PropTypes.array,
  planets: PropTypes.array,
  inventory: PropTypes.array,
  eventsHappened: PropTypes.array,
};
