import React from "react";
import PropTypes from "prop-types";
import debounce from "../../utils/debounce";

const Icon = ({ datatestid, cls, onClickAction }) => {
  return (
    <i
      data-testid={datatestid}
      className={cls}
      onClick={onClickAction ? debounce(onClickAction, 200) : null}
    ></i>
  );
};

export default Icon;

Icon.propTypes = {
  datatestid: PropTypes.string,
  cls: PropTypes.string,
  onClickAction: PropTypes.func,
};
