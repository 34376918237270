import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStoreData, notify } from "./utils";
import { googleLogout } from "@react-oauth/google";
import { cloneDeep } from "lodash";
import * as api from "../api";

const INITIAL_STATE = {
  status: null,
  currentUser: null,
};

export const signin = createAsyncThunk("auth/signin", async (formData) => {
  try {
    const { data } = await api.signIn(formData);
    return data;
  } catch (error) {
    notify(error.response.data.message);
  }
});

export const externalSignin = createAsyncThunk(
  "auth/externalSignin",
  async (response) => {
    try {
      const { data } = await api.externalSignIn(response);
      return data;
    } catch (error) {
      notify(error.response.data.message);
    }
  }
);

export const signup = createAsyncThunk("auth/signup", async (formData) => {
  try {
    const { data } = await api.signUp(formData);
    return data;
  } catch (error) {
    notify(error.response.data.message);
  }
});

export const signupdemo = createAsyncThunk("auth/signupdemo", async () => {
  try {
    const { data } = await api.signUpDemo();
    return data;
  } catch (error) {
    notify(error.response.data.message);
  }
});

export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async ({ id }) => {
    try {
      await api.deleteUser(id);
      return id;
    } catch (error) {
      console.log(error);
      notify(error.response.data.message);
    }
  }
);

export const updateProgress = createAsyncThunk(
  "auth/updateProgress",
  async (update) => {
    try {
      const { data } = await api.updateProgress(update);
      return data;
    } catch (error) {
      console.log(error);
      notify(error.response.data.message);
    }
  }
);

export const resetUserProgress = createAsyncThunk(
  "auth/resetUserProgress",
  async () => {
    try {
      const { data } = await api.resetUserProgress();
      return data;
    } catch (error) {
      console.log(error);
      notify(error.response.data.message);
    }
  }
);

export const saveGame = createAsyncThunk("auth/saveGame", async (save) => {
  try {
    const { data } = await api.saveGame(save);
    return data;
  } catch (error) {
    console.log(error);
    notify(error.response.data.message);
  }
});

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ token, formData }) => {
    try {
      const { data } = await api.changePassword(token, formData);
      return data;
    } catch (error) {
      console.log(error);
      notify(error.response.data.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (formData) => {
    try {
      const { data } = await api.resetPassword(formData);
      return data;
    } catch (error) {
      console.log(error);
      notify(error.response.data.message);
    }
  }
);

export const updateUserAccount = createAsyncThunk(
  "auth/updateUserAccount",
  async ({ id, userData }) => {
    try {
      const { data } = await api.updateUserAccount(id, userData);
      return data;
    } catch (error) {
      console.log(error);
      notify(error.response.data.message);
    }
  }
);

const slice = createSlice({
  name: "auth",
  initialState: getStoreData("user.auth", INITIAL_STATE),

  reducers: {
    logout: (state) => {
      localStorage.removeItem("rocket-english-profile");
      state.currentUser = null;
      state.status = null;
      googleLogout();
    },
    currentPlanetSet: (state, action) => {
      state.currentUser.progress.currentPlanet = action.payload;
    },
    tourModeSwitched: (state, action) => {
      state.currentUser.progress.tour = action.payload;
    },
    trophyCollected: (state, action) => {
      const { id, reward } = action.payload;
      state.currentUser.progress.trophiesCollected = [
        ...state.currentUser.progress.trophiesCollected,
        id,
      ];
      state.currentUser.progress.exp += reward;
    },
    expAdded: (state, action) => {
      const exp = state.currentUser.progress.exp + action.payload;
      state.currentUser.progress.exp += action.payload;
      state.currentUser.progress.characterLvl = Math.floor(exp / 1000) + 1;
      if (action.payload > 0) {
        notify(`+${action.payload} exp`);
      }
    },
    creditsAdded: (state, action) => {
      state.currentUser.progress.inventory.credits += action.payload;
      if (action.payload > 0) {
        notify(`+${action.payload} [!]`);
      }
    },
    creditsSubtracted: (state, action) => {
      if (state.currentUser.progress.inventory.credits - action.payload < 0) {
        state.currentUser.progress.inventory.credits = 0;
      } else {
        state.currentUser.progress.inventory.credits -= action.payload;
        if (action.payload > 0) {
          notify(`-${action.payload} [!]`);
        }
      }
    },
    eventCounterIncremented: (state, action) => {
      const events = { ...state.currentUser.progress.eventsHappened };
      events[action.payload] += 1;
      state.currentUser.progress.eventsHappened = events;
    },
    playerLvlCalculated: (state) => {
      state.currentUser.progress.characterLvl =
        Math.floor(state.currentUser.progress.exp / 1000) + 1;
    },
    vortexUnlocked: (state) => {
      state.currentUser.progress.vortexAccess = true;
      notify("Vortex unlocked");
    },
    gameFinished: (state) => {
      state.currentUser.progress.currentPlanet = "menu";
      state.currentUser.progress.gameFinished = true;
      state.currentUser.progress.narration.unlocked = [
        ...state.currentUser.progress.narration.unlocked,
        "menu",
      ];
      notify("Congratulations: GAME FINISHED!");
    },
    newGamePlusStarted: (state) => {
      state.currentUser.progress.currentPlanet = "centuria";
      state.currentUser.progress.gameFinished = true;
      state.currentUser.progress.narration.completed = [
        ...state.currentUser.progress.narration.completed,
        "menu",
      ];
      notify("WELCOME TO NEW GAME PLUS");
    },
    narrationCompleted: (state, action) => {
      state.currentUser.progress.narration.completed = [
        ...state.currentUser.progress.narration.completed,
        action.payload,
      ];
    },
    spcialCompleted: (state, action) => {
      const dialogues = cloneDeep(state.currentUser.progress.dialogues);
      dialogues[action.payload].specialCompleted = true;

      state.currentUser.progress.dialogues = dialogues;
    },
    narrationUnlocked: (state, action) => {
      state.currentUser.progress.narration.unlocked = [
        ...state.currentUser.progress.narration.unlocked,
        action.payload,
      ];
    },
    dialogueHided: (state, action) => {
      const { planet, id } = action.payload;
      const dialogues = cloneDeep(state.currentUser.progress.dialogues);
      if (
        dialogues[planet].shownOnce.includes(id) ||
        dialogues[planet].spcialCompleted
      ) {
        dialogues[planet].hidden.push(id);
        state.currentUser.progress.dialogues = dialogues;
      }
    },
    dialogueShownAndCompleted: (state, action) => {
      const { id, planet, event } = action.payload;
      const dialogues = cloneDeep(state.currentUser.progress.dialogues);
      dialogues[planet].specialCompleted = true;
      dialogues[planet].hidden.push(id);
      const events = { ...state.currentUser.progress.eventsHappened };
      events[event] += 1;
      state.currentUser.progress.dialogues = dialogues;
      state.currentUser.progress.eventsHappened = events;
    },
    dialogueCompleted: (state, action) => {
      const { id, unlockId, planet } = action.payload;
      const dialogues = cloneDeep(state.currentUser.progress.dialogues);
      dialogues[planet].completed.push(id);

      if (
        (dialogues[planet].shownOnce.includes(id) &&
          dialogues[planet].specialActions.includes(id)) ||
        dialogues[planet].specialCompleted === true
      ) {
        dialogues[planet].hidden.push(id);
      }

      if (dialogues[planet].shownOnce.includes(id) && unlockId !== 0) {
        dialogues[planet].hidden = dialogues[planet].hidden.filter(
          (index) => index !== unlockId
        );
      }
      state.currentUser.progress.dialogues = dialogues;
    },
    ufoEncountered: (state, action) => {
      const { planet, win } = action.payload;
      state.currentUser.progress.ufoDefeated = [
        ...state.currentUser.progress.ufoDefeated,
        planet,
      ];
      const events = { ...state.currentUser.progress.eventsHappened };
      if (win) {
        events["ufoDefeated"] += 1;
      } else {
        events["getRobbed"] += 1;
      }
      state.currentUser.progress.eventsHappened = events;
    },
    movementPointsAdded: (state, action) => {
      if (
        state.currentUser.progress.movement.currentMovePoints + action.payload >
        state.currentUser.progress.movement.maxMovePoints
      ) {
        state.currentUser.progress.movement.currentMovePoints =
          state.currentUser.progress.movement.maxMovePoints;
      } else {
        state.currentUser.progress.movement.currentMovePoints += action.payload;
      }
    },
    movementPointsSubtracted: (state, action) => {
      if (
        action.payload <= state.currentUser.progress.movement.currentMovePoints
      ) {
        state.currentUser.progress.movement.currentMovePoints -= action.payload;
      }
    },
    movementPointsSet: (state, action) => {
      const { current, max } = action.payload;
      state.currentUser.progress.movement.currentMovePoints = current;
      state.currentUser.progress.movement.maxMovePoints = max;
    },
    rocketUpgraded: (state) => {
      state.currentUser.progress.rocketLvl += 1;
      state.currentUser.progress.exp += 1000;
      notify("Rocket upgraded\n\n + 1000 exp");
    },
    expeditionArrived: (state, action) => {
      const { exp, planet } = action.payload;
      state.currentUser.progress.exp += exp;
      state.currentUser.progress.currentPlanet = planet;
      state.currentUser.progress.narration.completed = [
        ...state.currentUser.progress.narration.completed,
        planet,
      ];
    },
    turnCounterIncremented: (state) => {
      state.currentUser.progress.turnNumber += 1;
    },
    availablePlanetSet: (state, action) => {
      const planets = { ...state.currentUser.progress.planets };
      if (!planets.discovered.includes(action.payload)) {
        planets.discovered.push(action.payload);
      }
      planets.available = [action.payload];
      state.currentUser.progress.planets = planets;
    },
    itemSubtracted: (state, action) => {
      const { item, amount } = action.payload;
      if (state.currentUser.progress.inventory[item] - amount < 0) {
        state.currentUser.progress.inventory[item] = 0;
      } else {
        state.currentUser.progress.inventory[item] -= amount;
      }
    },
    freeItemAdded: (state, action) => {
      const { item, amount } = action.payload;
      state.currentUser.progress.inventory[item] += amount;
    },
    freeItemsAdded: (state, action) => {
      for (const [item, amount] of Object.entries(action.payload)) {
        state.currentUser.progress.inventory[item] += amount;
      }
    },
    itemsExchanged: (state, action) => {
      const { get, give } = action.payload;

      for (const [item, amount] of Object.entries(give)) {
        if (state.currentUser.progress.inventory[item] >= amount) {
          state.currentUser.progress.inventory[item] -= amount;
          notify("New item received");
        } else {
          notify("Not enough items");
        }
      }
      for (const [item, amount] of Object.entries(get)) {
        if (!state.currentUser.progress.inventory[item]) {
          state.currentUser.progress.inventory[item] = amount;
        } else {
          state.currentUser.progress.inventory[item] += amount;
        }
      }
    },
    itemsGiven: (state, action) => {
      const { give } = action.payload;
      for (const [item, amount] of Object.entries(give)) {
        state.currentUser.progress.inventory[item] -= amount;
      }
    },
    itemPurchased: (state, action) => {
      const { item, amount, price, multiplier } = action.payload;
      const inventory = { ...state.currentUser.progress.inventory };
      if (inventory.credits >= price * multiplier) {
        inventory[item] += amount;
        inventory.credits -= price * multiplier;
        state.currentUser.progress.inventory = inventory;
        notify("New item received");
      } else {
        notify("Not enough items");
      }
    },
    allItemsLost: (state) => {
      const inventory = { ...state.currentUser.progress.inventory };
      for (let item of [
        "credits",
        "stardust",
        "steel",
        "aluminum",
        "crystal",
      ]) {
        inventory[item] = 0;
      }
      state.currentUser.progress.inventory = inventory;
    },
    favoriteItemAdded: (state, action) => {
      const { word, definition } = action.payload;
      const favorites = [...state.currentUser.progress.favorites];
      favorites.push({ word, definition });
      state.currentUser.progress.favorites = favorites;
    },
    favoriteItemRemoved: (state, action) => {
      state.currentUser.progress.favorites =
        state.currentUser.progress.favorites.filter(
          (item) => item.word !== action.payload
        );
    },
    mercenaryHired: (state, action) => {
      const { id, price } = action.payload;
      const mercenaries = { ...state.currentUser.progress.mercenaries };
      if (state.currentUser.progress.inventory.credits >= price) {
        mercenaries.hired.push(id);
        state.currentUser.progress.inventory.credits -= price;
        state.currentUser.progress.mercenaries = mercenaries;
        notify("Mercenary hired");
      } else {
        notify("Not enough credits");
      }
    },
    mercenaryFired: (state, action) => {
      const { id, price } = action.payload;
      const mercenaries = { ...state.currentUser.progress.mercenaries };
      mercenaries.hired = mercenaries.hired.filter((merc) => merc !== id);
      mercenaries.selected = mercenaries.selected.filter((merc) => merc !== id);
      state.currentUser.progress.inventory.credits += price / 2;
      state.currentUser.progress.mercenaries = mercenaries;
      notify("Mercenary fired");
    },
    mercenariesStatusChanged: (state, action) => {
      const { idArray, status } = action.payload;
      const mercenaries = { ...state.currentUser.progress.mercenaries };

      for (let id of idArray) {
        if (status === "mark") {
          if (!mercenaries.selected.includes(id)) {
            mercenaries.selected.push(id);
          }
        } else if (status === "release") {
          mercenaries.selected = mercenaries.selected.filter(
            (merc) => merc !== id
          );
        } else if (status === "dead") {
          if (!mercenaries.dead.includes(id)) {
            mercenaries.dead.push(id);
          }

          mercenaries.selected = mercenaries.selected.filter(
            (merc) => merc !== id
          );
          mercenaries.sent = mercenaries.sent.filter((merc) => merc !== id);
          mercenaries.hired = mercenaries.hired.filter((merc) => merc !== id);
        } else if (status === "sent") {
          mercenaries.selected = mercenaries.selected.filter(
            (merc) => merc !== id
          );
          if (!mercenaries.sent.includes(id)) {
            mercenaries.sent.push(id);
          }
        } else if (status === "back") {
          mercenaries.selected = mercenaries.selected.filter(
            (merc) => merc !== id
          );
          mercenaries.sent = mercenaries.sent.filter((merc) => merc !== id);
        }
      }
      state.currentUser.progress.mercenaries = mercenaries;
    },
    mercenaryResurrected: (state, action) => {
      const mercenaries = { ...state.currentUser.progress.mercenaries };
      mercenaries.dead = mercenaries.dead.filter(
        (merc) => merc !== action.payload
      );
      state.currentUser.progress.mercenaries = mercenaries;
    },
    expeditionReturned: (state, action) => {
      const { items, idArray, status } = action.payload;
      const mercenaries = { ...state.currentUser.progress.mercenaries };

      for (const [item, amount] of Object.entries(items)) {
        if (item !== "exp") {
          state.currentUser.progress.inventory[item] += amount;
        }
      }
      state.currentUser.progress.exp += items.exp;

      for (let id of idArray) {
        if (status === "mark") {
          if (!mercenaries.selected.includes(id)) {
            mercenaries.selected.push(id);
          }
        } else if (status === "release") {
          mercenaries.selected = mercenaries.selected.filter(
            (merc) => merc !== id
          );
        } else if (status === "dead") {
          if (!mercenaries.dead.includes(id)) {
            mercenaries.dead.push(id);
          }

          mercenaries.selected = mercenaries.selected.filter(
            (merc) => merc !== id
          );
          mercenaries.sent = mercenaries.sent.filter((merc) => merc !== id);
          mercenaries.hired = mercenaries.hired.filter((merc) => merc !== id);
        } else if (status === "sent") {
          mercenaries.selected = mercenaries.selected.filter(
            (merc) => merc !== id
          );
          if (!mercenaries.sent.includes(id)) {
            mercenaries.sent.push(id);
          }
        } else if (status === "back") {
          mercenaries.selected = mercenaries.selected.filter(
            (merc) => merc !== id
          );
          mercenaries.sent = mercenaries.sent.filter((merc) => merc !== id);
        }
      }
      state.currentUser.progress.mercenaries = mercenaries;
    },
    taskFinished: (state, action) => {
      if (state.currentUser) {
        state.currentUser.progress.taskQueue =
          state.currentUser.progress.taskQueue.filter(
            (task) => task.id !== action.payload
          );
      }
    },
    taskAddedToQueue: (state, action) => {
      const queue = [...state.currentUser.progress.taskQueue];
      queue.push(action.payload);
      state.currentUser.progress.taskQueue = queue;
    },
    gamePauseStatusSwitched: (state, action) => {
      state.currentUser.progress.gamePaused = action.payload;
    },

    newGameStatusSwitched: (state, action) => {
      state.currentUser.progress.newGame = action.payload;
    },
  },

  extraReducers: {
    [signin.pending]: (state) => {
      state.status = "loading";
    },
    [signin.fulfilled]: (state, action) => {
      if (action.payload?.token) {
        localStorage.setItem(
          "rocket-english-profile",
          JSON.stringify({ credential: action.payload.token })
        );
        state.currentUser = action.payload.user;
        notify(`Welcome ${action.payload.user.name}`);
      }
      state.status = "success";
    },
    [signin.rejected]: (state) => {
      state.status = "failed";
    },

    [externalSignin.pending]: (state) => {
      state.status = "loading";
    },
    [externalSignin.fulfilled]: (state, action) => {
      if (action.payload?.token) {
        localStorage.setItem(
          "rocket-english-profile",
          JSON.stringify({ credential: action.payload.token })
        );
        state.currentUser = action.payload.user;
        notify(`Hello ${action.payload.user.name}`);
      }
      state.status = "success";
    },
    [externalSignin.rejected]: (state) => {
      state.status = "failed";
    },
    [signup.pending]: (state) => {
      state.status = "loading";
    },
    [signup.fulfilled]: (state, action) => {
      if (action.payload?.token) {
        localStorage.setItem(
          "rocket-english-profile",
          JSON.stringify({ credential: action.payload.token })
        );
        state.currentUser = action.payload.user;
        notify(`Hello ${action.payload.user.name}`);
      }
      state.status = "success";
    },
    [signup.rejected]: (state) => {
      state.status = "failed";
    },

    [signupdemo.pending]: (state) => {
      state.status = "loading";
    },
    [signupdemo.fulfilled]: (state, action) => {
      if (action.payload?.token) {
        localStorage.setItem(
          "rocket-english-profile",
          JSON.stringify({ credential: action.payload.token })
        );
        state.currentUser = action.payload.user;
      }
      state.status = "success";
    },
    [signupdemo.rejected]: (state) => {
      state.status = "failed";
    },

    [deleteUser.pending]: (state) => {
      state.status = "loading";
    },
    [deleteUser.fulfilled]: (state, action) => {
      if (!action.payload?.user) {
        localStorage.removeItem("rocket-english-profile");
        state.currentUser.progress.newGame = true;
        state.currentUser = null;
        state.status = null;
        googleLogout();
        notify("Account deleted");
      }
      state.status = "success";
    },
    [deleteUser.rejected]: (state) => {
      state.status = "failed";
    },
    [updateProgress.pending]: (state) => {
      state.status = "loading";
    },
    [updateProgress.fulfilled]: (state, action) => {
      state.currentUser.progress = action.payload;
      state.status = "success";
    },
    [updateProgress.rejected]: (state) => {
      state.status = "failed";
    },
    [resetUserProgress.pending]: (state) => {
      state.status = "loading";
    },
    [resetUserProgress.fulfilled]: (state, action) => {
      state.currentUser.progress = action.payload;
      state.status = "success";
    },
    [resetUserProgress.rejected]: (state) => {
      state.status = "failed";
    },
    [saveGame.pending]: (state) => {
      state.status = "loading";
    },
    [saveGame.fulfilled]: (state, action) => {
      state.currentUser.progress = action.payload;
      notify("Game saved");
      state.status = "success";
    },
    [saveGame.rejected]: (state) => {
      state.status = "failed";
    },
    [changePassword.pending]: (state) => {
      state.status = "loading";
    },
    [changePassword.fulfilled]: (state, action) => {
      if (action.payload?.message) {
        notify(action.payload.message);
        window.location.href = "/auth";
      }
      state.status = "success";
    },
    [changePassword.rejected]: (state) => {
      state.status = "failed";
    },
    [resetPassword.pending]: (state) => {
      state.status = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      if (action.payload?.message) {
        window.location.href = "/auth";
      }
      state.status = "success";
    },
    [resetPassword.rejected]: (state) => {
      state.status = "failed";
    },
    [updateUserAccount.pending]: (state) => {
      state.status = "loading";
    },
    [updateUserAccount.fulfilled]: (state, action) => {
      if (action.payload?.user.name && action.payload?.user.email) {
        state.currentUser.name = action.payload.user.name;
        state.currentUser.email = action.payload.user.email;
        notify("Changes saved");
      }
      state.status = "success";
    },
    [updateUserAccount.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export const {
  logout,
  currentPlanetSet,
  tourModeSwitched,
  trophyCollected,
  expAdded,
  creditsAdded,
  creditsSubtracted,
  eventCounterIncremented,
  playerLvlCalculated,
  vortexUnlocked,
  gameFinished,
  newGamePlusStarted,
  narrationCompleted,
  spcialCompleted,
  narrationUnlocked,
  dialogueHided,
  dialogueShownAndCompleted,
  dialogueCompleted,
  ufoEncountered,
  movementPointsAdded,
  movementPointsSubtracted,
  movementPointsSet,
  rocketUpgraded,
  expeditionArrived,
  turnCounterIncremented,
  availablePlanetSet,
  itemSubtracted,
  freeItemAdded,
  freeItemsAdded,
  itemsExchanged,
  itemsGiven,
  itemPurchased,
  allItemsLost,
  favoriteItemAdded,
  favoriteItemRemoved,
  mercenaryHired,
  mercenaryFired,
  mercenariesStatusChanged,
  mercenaryResurrected,
  expeditionReturned,
  taskFinished,
  taskAddedToQueue,
  gamePauseStatusSwitched,
  newGameStatusSwitched,
} = slice.actions;
export default slice.reducer;
