import React, { useState } from "react";
import PropTypes from "prop-types";
import Answer from "./answer";
import { useDispatch, useSelector } from "react-redux";
import { dialogueCompleted } from "../../store/auth";
import dialogues from "../../utils/dialogues";
import "./questions.css";

const Questions = ({ place, handleConversation }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);

  const dispatch = useDispatch();

  const { currentPlanet, dialogues: texts } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          dialogues: { texts: [] },
        }
  );

  const { completed, hidden } = texts[currentPlanet];

  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  const ifVisited = (id) => {
    return completed.includes(id)
      ? "visited dialogue-line cursor-pointer"
      : "dialogue-line cursor-pointer";
  };

  return (
    <>
      {!showAnswer && (
        <ul id={"questions"}>
          {dialogues[currentPlanet]
            .filter((elem) => elem.place === place && !hidden.includes(elem.id))
            .map((elem) => (
              <li
                key={elem.id}
                className={ifVisited(elem.id)}
                onClick={() => {
                  setSelectedQuestion(elem.id);
                  handleShowAnswer(!showAnswer);
                  dispatch(
                    dialogueCompleted({
                      id: elem.id,
                      unlockId: elem.unlocks,
                      planet: currentPlanet,
                    })
                  );
                }}
              >
                {elem.question}
              </li>
            ))}
          <li
            className="visited dialogue-line cursor-pointer"
            onClick={handleConversation}
          >
            That's all...
          </li>
        </ul>
      )}

      {showAnswer && (
        <ul id={"answers"}>
          <Answer
            handleConversation={handleConversation}
            showAnswer={handleShowAnswer}
            questionID={selectedQuestion}
          />
        </ul>
      )}
    </>
  );
};

export default Questions;

Questions.propTypes = {
  place: PropTypes.string,
  handleConversation: PropTypes.func,
};
