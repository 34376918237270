import React from "react";
import PropTypes from "prop-types";
import "./articleWithUnderlinedHeader.css";

const ArticleUnderlined = ({ header, headerSize, text }) => {
  const renderHeader = () => {
    if (headerSize === "h2") {
      return <h2 className="title">{header}</h2>;
    } else if (headerSize === "h3") {
      return <h3 className="title">{header}</h3>;
    } else if (headerSize === "h4") {
      return <h4 className="title">{header}</h4>;
    } else if (headerSize === "h5") {
      return <h5 className="title">{header}</h5>;
    } else {
      return <h1 className="title">{header}</h1>;
    }
  };

  return (
    <header className="content">
      {renderHeader()}
      <hr className="underline" />
      <p className="text-description">{text}</p>
    </header>
  );
};

export default ArticleUnderlined;

ArticleUnderlined.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
  headerSize: PropTypes.string,
};
