/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import PuffLoader from "react-spinners/PuffLoader";
import { useDispatch, useSelector } from "react-redux";
import { itemSubtracted } from "../../store/auth";
import api from "../../utils/api";
import "./quiz.css";

const Quiz = ({
  submitAnswer,
  word,
  addAnswerToWord,
  fillTheWord,
  count,
  len,
  skipDefinition,
  showMenu,
  showQuiz,
}) => {
  const [definition, setDefinition] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { inventory } = useSelector(
    (state) => state.user.auth.currentUser.progress
  );

  useEffect(() => {
    getDefinition();
  }, []);

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => submitAnswer(data["answer"]);

  const getDefinition = async () => {
    try {
      setErrorMessage(false);
      const def = await api.getWordData(word);
      addAnswerToWord(word, def);
      setDefinition(def);
    } catch (err) {
      console.error(err.message);
      if (err.message === "Network Error") {
        setErrorMessage("It looks like there is a connection problem ...");
      } else {
        setErrorMessage(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const useWord = () => {
    fillTheWord(word);
    dispatch(itemSubtracted({ item: "word", amount: 1 }));
  };

  const renderWord = () => {
    if (inventory.word > 0) {
      return (
        <button className="button small" onClick={useWord}>
          Reveal the word
        </button>
      );
    }
  };

  const renderContentOrError = () => {
    if (!errorMessage) {
      return (
        <>
          <p>{`${count} / ${len}`}</p>
          <p>{definition}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              placeholder="Answer"
              type="text"
              {...register("answer", {
                required: true,
                minLength: 2,
                maxLength: 15,
                pattern: /^[A-Za-z]+$/i,
              })}
            />
            <button type="submit" className="button small">
              Submit
            </button>
            {renderWord()}
          </form>
          <button className="button small" onClick={() => skipDefinition()}>
            Skip
          </button>
        </>
      );
    } else {
      return (
        <>
          <p>{errorMessage}</p>
          <button className="button small" onClick={getDefinition}>
            Try again
          </button>
        </>
      );
    }
  };
  const renderSpinner = (size) => {
    return (
      <PuffLoader
        loading={loading}
        size={size}
        color={"white"}
        speedMultiplier={0.8}
      />
    );
  };

  return (
    <div className="quiz-container width-80">
      <article className="padding-places">
        <div className="loader">{loading && renderSpinner("10rem")}</div>
        {!loading && renderContentOrError()}
      </article>
      <button
        onClick={() => {
          showQuiz();
          showMenu();
        }}
        className="button small"
      >
        Go back
      </button>
    </div>
  );
};

export default Quiz;

Quiz.propTypes = {
  submitAnswer: PropTypes.func,
  word: PropTypes.string,
  addAnswerToWord: PropTypes.func,
  fillTheWord: PropTypes.func,
  count: PropTypes.number,
  len: PropTypes.string,
  skipDefinition: PropTypes.func,
  showMenu: PropTypes.func,
  showQuiz: PropTypes.func,
};
