import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));

const ModalMenu = ({
  content,
  contentLabel,
  appElement,
  onRequestClose,
  isOpen,
  toggleModal,
  confirmActionCallback,
}) => {
  const modalStyle = {
    content: {
      textAlign: "center",
      boxShadow: "0 0 25px rgba(0, 0, 0, 0.5)",
      borderColor: "rgb(1, 9, 27)",
      backgroundColor: "rgb(1, 9, 27)",
      borderRadius: "15px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      marginTop: "1.5rem",
      transform: "translate(-50%, -50%)",
      padding: "3rem 1rem",
      maxWidth: "90%",
      maxHeight: "70%",
    },
  };
  return (
    <Modal
      id="confirmationModal"
      appElement={appElement}
      style={modalStyle}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
    >
      <p>Are you sure?</p>
      <p>{content}</p>
      <button className="modal button bg-green" onClick={confirmActionCallback}>
        Yes
      </button>
      <button className="modal button bg-red" onClick={toggleModal}>
        No
      </button>
    </Modal>
  );
};

export default ModalMenu;

ModalMenu.propTypes = {
  appElement: PropTypes.object,
  toggleModal: PropTypes.func,
  confirmActionCallback: PropTypes.func,
  content: PropTypes.string,
  contentLabel: PropTypes.string,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
};
