import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const useCheckPlaceAccess = (place) => {
  const history = useHistory();
  const { planets } = useSelector((state) => state.environment.planet);
  const { currentUser } = useSelector((state) => state.user.auth);
  const progress = currentUser?.progress;
  const currentPlanet = progress?.currentPlanet;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("rocket-english-profile"));
    if (!currentUser || !user) {
      history.push("/auth");
    } else {
      const planet = planets.find((planet) => planet.name === currentPlanet);

      if (!planet) {
        history.push("/space");
      } else {
        const redirect = planet.places.includes(place);
        if (!redirect) history.push("/space");
      }
    }
  }, [currentUser, currentPlanet, history, place, planets]);
};

export default useCheckPlaceAccess;
