import React from "react";
import Planet from "./planet";
import PlaceBasic from "../universal/placeBasic";
import PlaceLaunchPad from "../universal/placeLaunchPad";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import useCheckPlanetAccess from "../hooks/useCheckPlanetAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import {
  casino_png,
  casino_webp,
  quiz_png,
  quiz_webp,
  shop_png,
  shop_webp,
  rocket_png,
  rocket_webp,
  university_png,
  university_webp,
} from "./imagesIndex";

const Crion = () => {
  const { planets } = useSelector((state) => state.environment.planet);
  const { description, infrastructure, climate, inhabitants } = planets.find(
    (planet) => planet.name === "crion"
  );
  const dispatch = useDispatch();

  useChangeGameStatus("crion");
  useCheckPlanetAccess("crion");

  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Shop"}
      link={"shop"}
      img_webp={shop_webp}
      img_png={shop_png}
      alt={
        "A big black neon sign with red lettering that says open. A shop logo."
      }
      description={"You can buy a lot of useful things here."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Casino"}
      link={"casino"}
      img_webp={casino_webp}
      img_png={casino_png}
      alt={"A big pink neon sign with the word casino. A Casino logo."}
      description={"Be careful. Gambling is addictive."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"School"}
      link={"quiz"}
      img_webp={quiz_webp}
      img_png={quiz_png}
      alt={"A thick red book. A school logo."}
      description={"You can test yourself and gain exp here."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Factory"}
      link={"factory"}
      img_webp={rocket_webp}
      img_png={rocket_png}
      alt={
        "A large robot and a space rocket, as well as night and stars in the background. Factory logo."
      }
      description={"Here you can upgrade your rocket."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"University"}
      link={"university"}
      img_webp={university_webp}
      img_png={university_png}
      alt={"A diploma with a blue ribbon. A university logo."}
      description={
        "The challenge here is to fill in the gaps in the text. It can also be your own text."
      }
    />,
    <PlaceLaunchPad
      onDragStart={handleDragStart}
      title={"Launch pad"}
      prevPlanet={"centuria"}
      nextPlanet={"therion"}
      prevLabel={"Back to Centuria"}
      nextLabel={"Go to Therion"}
      reqUserLvlNext={5}
      reqRocketLvlNext={2}
      reqUserLvlPrev={100}
      reqRocketLvlPrev={5}
    />,
  ];

  return (
    <div
      className="crion-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <Planet
        key={Math.random()}
        planet={"crion"}
        planetDescription={description}
        places={items}
        infrastructure={infrastructure}
        climate={climate}
        inhabitants={inhabitants}
      />
    </div>
  );
};

export default Crion;
