import React from "react";
import PropTypes from "prop-types";

const ActionButton = ({ callbackFnc, cls, title }) => {
  return (
    <button onClick={callbackFnc} className={cls}>
      {title}
    </button>
  );
};

export default ActionButton;

ActionButton.propTypes = {
  callbackFnc: PropTypes.func,
  cls: PropTypes.string,
  title: PropTypes.string,
};
