import React, { useRef } from "react";
import DialogueMenu from "../universal/dialogueMenu";
import NpcForHireCard from "../universal/npcForHireCard";
import AliceCarousel from "react-alice-carousel";
import renders from "../../utils/renders";
import bar_webp from "../../images/bar.webp";
import bar_png from "../../images/bar.png";
import HeaderWithLogo from "../universal/headerWithLogo";
import Header from "../universal/header";
import LinkButton from "../universal/linkButton";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import useCheckPlaceAccess from "../hooks/useCheckPlaceAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import { getMercenariesForHire } from "../../store/helpers";
import dialogues from "../../utils/dialogues";
import "../planets/planet.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "./bar.css";
import "../../App.css";

export const Bar = () => {
  useCheckPlaceAccess("bar");
  useChangeGameStatus();

  const mercenariesList = useRef(null);
  const dispatch = useDispatch();
  const { currentPlanet, mercenaries } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          mercenaries: { dead: [], hired: [] },
        }
  );
  const { mercenaries: npcForHire } = useSelector(
    (state) => state.environment.npc
  );

  const placeDescription =
    "Here you can hire mercenaries who will be happy to go out for youin search of valuable goods for an appropriate reward. You can manage mercenaries in your inventory screen.";

  const renderMercenaries = () => {
    const npc = getMercenariesForHire(mercenaries, npcForHire, currentPlanet);

    if (npc) {
      return npc.map((npc) => <NpcForHireCard mercenary={npc} />);
    } else {
      return null;
    }
  };

  mercenariesList.current = renderMercenaries();

  return (
    <main
      id="bar-container"
      className="bar-wrapper flex-auto main-wrapper"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <section id="bar" className="header-container">
        <HeaderWithLogo
          headerSize={"h2"}
          header={"bar"}
          text={placeDescription}
          webp={bar_webp}
          png={bar_png}
          size={"150em"}
          alt={"A Shiny black and pink drink neon sign. A bar logo."}
        />
        <section id="mercenaries-list">
          <Header headerSize={"h3"} header={"hire"} underline />
          <article className="planet-activities-container">
            <Header headerSize={"h4"} header={"mercenaries"} />
            {mercenariesList.current.length !== 0 && (
              <AliceCarousel
                disableDotsControls
                controlsStrategy={"responsive"}
                responsive={renders.carousel}
                keyboardNavigation
                infinite
                items={mercenariesList.current}
              />
            )}
            {mercenariesList.current.length === 0 && (
              <p className="place-description">
                There is no mercenaries to hire. Visit another planet.
              </p>
            )}
          </article>
        </section>
        <section>
          <Header headerSize={"h3"} header={"talk"} underline />
          <article>
            <Header headerSize={"h4"} header={"bartender"} />
            {dialogues[currentPlanet].length !== 0 && (
              <DialogueMenu place={"bar"} />
            )}
          </article>
        </section>
        <LinkButton destination={currentPlanet} title={"walk away"} />
      </section>
    </main>
  );
};
export default Bar;
