import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import useCheckPlanetAccess from "../hooks/useCheckPlanetAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import Planet from "./planet";
import PlaceBasic from "../universal/placeBasic";
import PlaceLaunchPad from "../universal/placeLaunchPad";
import PlaceVortex from "../universal/placeVortex";
import {
  casino_png,
  casino_webp,
  quiz_png,
  quiz_webp,
  shop_webp,
  shop_png,
  vortex_webp,
  vortex_png,
  smuggler_png,
  smuggler_webp,
} from "./imagesIndex";

const Centuria = () => {
  const { planets } = useSelector((state) => state.environment.planet);
  const { description, infrastructure, climate, inhabitants } = planets.find(
    (planet) => planet.name === "centuria"
  );
  const dispatch = useDispatch();

  useChangeGameStatus("centuria");
  useCheckPlanetAccess("centuria");

  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Shop"}
      link={"shop"}
      img_webp={shop_webp}
      img_png={shop_png}
      alt={
        "A big black neon sign with red lettering that says open. A shop logo."
      }
      description={"You can buy a lot of useful things here."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Casino"}
      link={"casino"}
      img_webp={casino_webp}
      img_png={casino_png}
      alt={"The big pink neon sign with the word casino. A Casino logo."}
      description={"Be careful. Gambling is addictive."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"School"}
      link={"quiz"}
      img_webp={quiz_webp}
      img_png={quiz_png}
      alt={"A thick red book. A school logo."}
      description={"You can test yourself and gain exp here."}
    />,
    <PlaceVortex
      onDragStart={handleDragStart}
      title={"Vortex"}
      img_webp={vortex_webp}
      img_png={vortex_png}
    />,
    <PlaceLaunchPad
      onDragStart={handleDragStart}
      title={"Launch pad"}
      prevPlanet={"xillon"}
      nextPlanet={"crion"}
      prevLabel={"Back to Xillon"}
      nextLabel={"Go to Crion"}
      reqUserLvlNext={1}
      reqRocketLvlNext={5}
      reqUserLvlPrev={80}
      reqRocketLvlPrev={1}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Smuggler"}
      link={"smuggler"}
      img_webp={smuggler_webp}
      img_png={smuggler_png}
      alt={
        "A scary looking bottle with a skull on the label, the symbol of the smuggler."
      }
      description={
        "He will give away the most valuable thing for a little help."
      }
    />,
  ];

  return (
    <div
      className="centuria-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <Planet
        key={Math.random()}
        planet={"centuria"}
        planetDescription={description}
        places={items}
        infrastructure={infrastructure}
        climate={climate}
        inhabitants={inhabitants}
      />
    </div>
  );
};

export default Centuria;
