import React, { useEffect, useCallback } from "react";
import Emitter from "../../utils/emitter";
import { useDispatch, useSelector } from "react-redux";
import { mercenariesStatusChanged, expeditionReturned } from "../../store/auth";
import { getSentAndAliveMercenaries } from "../../store/helpers";

const Expedition = () => {
  const { taskQueue, mercenaries } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          taskQueue: [],
          mercenaries: [],
        }
  );

  const { mercenaries: npcList } = useSelector(
    (state) => state.environment.npc
  );

  const findMercenaryProperty = useCallback(
    (id, property) => {
      const mercenary = npcList.find((npc) => npc._id === id);
      return mercenary[property];
    },
    [npcList]
  );

  const dispatch = useDispatch();

  const handleFightIfLose = useCallback(
    (difficulty) => {
      let defendedCounter = 0;
      const fightingMercenaries = [];

      const numberOfAttacked = Math.floor(Math.random() * difficulty);

      if (numberOfAttacked > 0) {
        for (let i = 0; i < numberOfAttacked; i++) {
          let aliveMercenaries = getSentAndAliveMercenaries(mercenaries);

          let randomMercenary =
            aliveMercenaries[
              Math.floor(Math.random() * aliveMercenaries.length)
            ];

          if (randomMercenary) {
            if (
              findMercenaryProperty(randomMercenary, "strength") / difficulty <=
              difficulty * 10
            ) {
              dispatch(
                mercenariesStatusChanged({
                  idArray: [randomMercenary],
                  status: "dead",
                })
              );
              if (
                !fightingMercenaries.find(
                  (mercenary) =>
                    mercenary[0] ===
                    findMercenaryProperty(randomMercenary, "name")
                )
              ) {
                fightingMercenaries.push([
                  findMercenaryProperty(randomMercenary, "name"),
                  "was killed",
                ]);
              }
            } else {
              if (defendedCounter < 1) {
                if (
                  !fightingMercenaries.find(
                    (mercenary) =>
                      mercenary[0] ===
                      findMercenaryProperty(randomMercenary, "name")
                  )
                ) {
                  fightingMercenaries.push([
                    findMercenaryProperty(randomMercenary, "name"),
                    "was defended",
                  ]);
                }
                defendedCounter++;
              }
            }
          }
        }
      }

      if (numberOfAttacked === 0) {
        fightingMercenaries.unshift([
          "The crew withdrew",
          "and nobody was hurt",
        ]);
      }

      fightingMercenaries.unshift(" ");

      Emitter.emit("SEND_CONTENT", fightingMercenaries);
    },
    [dispatch, findMercenaryProperty, mercenaries]
  );

  const startAnExpedition = useCallback(
    (data) => {
      const [task] = data;
      const founds = {};
      const winRate = [1.2, 1.5, 2.2];
      const loseOrWin = handleDifficultyLevels(task.difficulty);
      const result = loseOrWin[Math.floor(Math.random() * loseOrWin.length)];

      if (result === "loser") {
        handleFightIfLose(task.difficulty);

        dispatch(
          mercenariesStatusChanged({
            idArray: getSentAndAliveMercenaries(mercenaries),
            status: "back",
          })
        );

        Emitter.emit("SHOW_MODAL", true);
      } else {
        const rate = winRate[Math.floor(Math.random() * winRate.length)];
        const credits = Math.floor(task.difficulty * rate * 250);
        const exp = Math.floor(task.difficulty * rate * 150);
        const steel = Math.floor(task.difficulty * rate * 5) + 1;
        const aluminum = Math.floor(task.difficulty * rate * 3) + 1;
        const crystal = Math.floor(task.difficulty * rate * 1) + 1;
        founds["credits"] = credits;
        founds["exp"] = exp;
        founds["steel"] = steel;
        founds["aluminum"] = aluminum;
        founds["crystal"] = crystal;
        expeditionSummary(founds);

        dispatch(
          expeditionReturned({
            items: { exp, credits, steel, aluminum, crystal },
            idArray: getSentAndAliveMercenaries(mercenaries),
            status: "back",
          })
        );
        Emitter.emit("SHOW_MODAL", true);
      }
    },
    [dispatch, handleFightIfLose, mercenaries]
  );

  useEffect(() => {
    Emitter.on("START_AN_EXPEDITION", (expeditionTask) => {
      startAnExpedition(expeditionTask);
    });
    return () => {
      Emitter.off("START_AN_EXPEDITION");
    };
  }, [startAnExpedition, taskQueue]);

  const expeditionSummary = (object) => {
    const items = [];
    for (let [item, amount] of Object.entries(object)) {
      items.push([item, amount]);
    }
    Emitter.emit("SEND_CONTENT", items);
  };

  const handleDifficultyLevels = (difficulty) => {
    if (difficulty === 0.5) {
      return ["loser", "winner", "winner"];
    } else if (difficulty === 1) {
      return ["loser", "winner"];
    } else if (difficulty === 2) {
      return ["loser", "loser", "winner"];
    } else if (difficulty === 3) {
      return ["loser", "loser", "loser", "winner"];
    }
  };

  return <></>;
};

export default Expedition;
