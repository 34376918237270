import React, { useState, useEffect, useCallback } from "react";
import { Switch, Redirect, useLocation } from "react-router";
import Nav from "./components/nav";
import { renderSummary } from "./utils/summary";
import Emitter from "./utils/emitter";
import Expedition from "./components/universal/expedition";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import decode from "jwt-decode";
import { logout } from "./store/auth";
import { getPlanets } from "./store/planets";
import { getMercenaries } from "./store/npc";
import { getTrophies } from "./store/trophies";
import ModalWindow from "./components/universal/modal";
import { routes } from "./routesIndex";
import "./App.css";

export const App = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("rocket-english-profile"))
  );
  const [modalTrigger, setModalTrigger] = useState(false);
  const [summary, setSummary] = useState([]);
  const { currentUser } = useSelector((state) => state.user.auth);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getPlanets());
    dispatch(getMercenaries());
    dispatch(getTrophies());
  }, [dispatch]);

  const toggleModal = useCallback(() => {
    setModalTrigger(!modalTrigger);
  }, [modalTrigger]);

  useEffect(() => {
    Emitter.on("SEND_CONTENT", (summaryContent) => setSummary(summaryContent));
    Emitter.on("SHOW_MODAL", () => toggleModal());
  }, [toggleModal]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    setUser(null);
    return <Redirect to={"/auth"} />;
  }, [dispatch]);

  useEffect(() => {
    const logged = user?.credential;
    if (!logged) {
      handleLogout();
    }
  }, [handleLogout, user]);

  useEffect(() => {
    const profile = localStorage.getItem("rocket-english-profile");

    if (!profile) {
      handleLogout();
    }

    if (!currentUser) {
      localStorage.setItem("rocket-english-profile", null);
      handleLogout();
    }

    const token = user?.credential;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) {
        handleLogout();
      }
    }
  }, [handleLogout, user, location, currentUser]);

  return (
    <GoogleOAuthProvider
      clientId={
        "41817525721-cmp3qn4nvpcae834m3p0pf5f61f9bmoj.apps.googleusercontent.com"
      }
    >
      <Nav />
      <Switch>{routes}</Switch>
      <Toaster />
      {currentUser && <Expedition />}
      <ModalWindow
        isOpen={modalTrigger}
        onRequestClose={toggleModal}
        contentLabel="Expedition summary modal"
        content={
          <ul>
            <li>
              <h4>Expedition results:</h4>
            </li>
            {renderSummary(summary, " ")}
          </ul>
        }
      />
    </GoogleOAuthProvider>
  );
};

export default App;
