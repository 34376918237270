import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gameFinished as endGame, availablePlanetSet } from "../../store/auth";

const PlaceVortex = ({ title, img_png, img_webp }) => {
  const { currentPlanet, gameFinished, vortexAccess } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          gameFinished: null,
          vortexAccess: null,
        }
  );

  const dispatch = useDispatch();

  const vortexLink = () => {
    return vortexAccess && !gameFinished ? "/" : `/${currentPlanet}`;
  };

  const manageLinkActions = () => {
    if (vortexAccess && !gameFinished) {
      dispatch(availablePlanetSet("menu"));
      dispatch(endGame());
    }
  };

  return (
    <article className={`padding-places border carousel-card`}>
      <h4>{title}</h4>
      <Link onClick={() => manageLinkActions()} to={vortexLink(currentPlanet)}>
        <picture className="image fit padding-inline-1">
          <source srcSet={img_webp} type="image/webp" />
          <source srcSet={img_png} type="image/png" />
          <img
            src={img_png}
            type="image/png"
            width="100em"
            height="auto"
            alt={"A colorful vortex. It is not known where it leads."}
          />
        </picture>
      </Link>
      <p className="align-center">
        {gameFinished
          ? "A strange vortex. You've been there before."
          : "A strange vortex. Who knows what's on the other side."}
      </p>
    </article>
  );
};

export default PlaceVortex;

PlaceVortex.propTypes = {
  title: PropTypes.string,
  img_png: PropTypes.string,
  img_webp: PropTypes.string,
};
