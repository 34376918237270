import axios from "axios";

const API = axios.create({
  baseURL: "https://web-production-34f8.up.railway.app",
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("rocket-english-profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("rocket-english-profile")).credential
    }`;
  }
  return req;
});

export const externalSignIn = (data) => API.post("/user/externalsignin", data);
export const signIn = (data) => API.post("/user/signin", data);
export const signUp = (data) => API.post("/user/signup", data);
export const signUpDemo = () => API.post("/user/signupdemo");
export const deleteUser = (id) => API.delete(`/user/${id}`);
export const updateProgress = (update) =>
  API.patch(`/user/updateprogress`, update);
export const resetUserProgress = () => API.patch(`/user/resetprogress`);
export const saveGame = (save) => API.patch(`/user/savegame`, save);
export const resetPassword = (data) => API.post("/user/resetpassword", data);
export const changePassword = (token, data) =>
  API.patch(`/user/changepassword/${token}`, data);
export const updateUserAccount = (id, update) =>
  API.patch(`/user/updateuseraccount/${id}`, update);

export const fetchPlanets = () => API.get("/planet");
export const fetchMercenaries = () => API.get("/npc/mercenary");
export const fetchTrophies = () => API.get("/trophy");
