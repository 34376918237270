import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({
  component: Component,
  path,
  render,
  notLoggedAccessOnly,
  ...rest
}) => {
  const [logged] = useState(
    JSON.parse(localStorage.getItem("rocket-english-profile"))
  );
  return (
    <Route
      {...rest}
      exact
      render={(props) => {
        if (notLoggedAccessOnly ? logged : !logged)
          return <Redirect to={notLoggedAccessOnly ? "/" : "/auth"} />;
        return Component ? <Component {...props} /> : render(...props);
      }}
    />
  );
};

export default ProtectedRoute;
