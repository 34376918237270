import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { notify } from "../../store/utils";
import { useDispatch, useSelector } from "react-redux";
import { movementPointsSubtracted } from "../../store/auth";

const PlaceBasic = ({ link, img_png, img_webp, alt, title, description }) => {
  const dispatch = useDispatch();
  const currentMovePoints = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress.movement.currentMovePoints
      : null
  );

  const renderLinkBasedOnMovementPoints = (requiredPoints) => {
    return currentMovePoints >= requiredPoints ? (
      <Link
        onClick={() => dispatch(movementPointsSubtracted(5))}
        to={`/${link}`}
        className={"no-decoration"}
      >
        <h4>{title}</h4>
        <picture className="image fit padding-inline-1">
          <source srcSet={img_webp} type="image/webp" />
          <source srcSet={img_png} type="image/png" />
          <img
            draggable="false"
            src={img_png}
            type="image/png"
            width="100em"
            height="auto"
            alt={alt}
          />
        </picture>
      </Link>
    ) : (
      <>
        <h4>{title}</h4>
        <picture className="image fit padding-inline-1">
          <source srcSet={img_webp} type="image/webp" />
          <source srcSet={img_png} type="image/png" />
          <img
            onClick={() => notify("5 move points required.")}
            src={img_png}
            type="image/png"
            width="100em"
            height="auto"
            alt={alt}
          />
        </picture>
      </>
    );
  };

  return (
    <>
      <article className="padding-places border carousel-card">
        {renderLinkBasedOnMovementPoints(5)}
        <p className="align-center">{description}</p>
      </article>
    </>
  );
};

export default PlaceBasic;

PlaceBasic.propTypes = {
  link: PropTypes.string,
  img_png: PropTypes.string,
  img_webp: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
