import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Trophy from "../universal/trophy";
import { navbarBgChanged } from "../../store/game";
import LinkButton from "../universal/linkButton";
import trophy_png from "../../images/trophy.png";
import trophy_webp from "../../images/trophy.webp";
import trophy_locked_png from "../../images/trophy_locked.png";
import trophy_locked_webp from "../../images/trophy_locked.webp";
import "react-alice-carousel/lib/alice-carousel.css";
import "./trophies.css";

const Trophies = () => {
  const { currentPlanet, trophiesCollected } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          trophiesCollected: [],
        }
  );
  const { trophies } = useSelector((state) => state.environment.trophy);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(navbarBgChanged(0));
    };
  }, [dispatch]);

  const renderTrophyList = () => {
    return trophies.map((trophy) => (
      <Trophy
        key={trophy._id}
        webp={trophy_webp}
        png={trophy_png}
        webp_locked={trophy_locked_webp}
        png_locked={trophy_locked_png}
        name={trophy.name}
        alt={trophy.name}
        collected={trophiesCollected.includes(trophy._id)}
        reward={trophy.reward}
        size={"10em"}
      />
    ));
  };

  return (
    <section
      className="trophies-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <div id="trophies">
        <section className="trophies-container main-background border border-radius padding margin-block-trophies-container">
          <div className="padding border">
            <h3>Trophies</h3>
            <article className="trophies-activities-container">
              {renderTrophyList()}
            </article>
            <LinkButton destination={currentPlanet} title={"go back"} />
          </div>
        </section>
      </div>
    </section>
  );
};

export default Trophies;
