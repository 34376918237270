import { combineReducers } from "redux";
import planetReducer from "./planets";
import npcReducer from "./npc";
import trophyReducer from "./trophies";
import gameReducer from "./game";

export default combineReducers({
  planet: planetReducer,
  npc: npcReducer,
  trophy: trophyReducer,
  game: gameReducer,
});
