import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import debounce from "../../utils/debounce";
import { useDispatch, useSelector } from "react-redux";
import { availablePlanetSet, movementPointsSubtracted } from "../../store/auth";
import { notify } from "../../store/utils";
import "./travelImage.css";

const TravelImage = ({
  requiredPlayerLvl,
  requiredRocketLvl,
  size,
  image_png,
  image_webp,
  destinationPlanet,
}) => {
  const dispatch = useDispatch();

  const { narration, movement, tour, characterLvl, rocketLvl } = useSelector(
    (state) =>
      state.user.auth.currentUser
        ? state.user.auth.currentUser.progress
        : {
            narration: null,
            movement: null,
            tour: null,
            characterLvl: null,
            rocketLvl: null,
          }
  );

  const checkIfPlanetDiscovered = () => {
    return characterLvl >= requiredPlayerLvl && rocketLvl >= requiredRocketLvl
      ? true
      : false;
  };

  const showProgressMessage = () => {
    if (!narration.unlocked.includes(destinationPlanet))
      notify("You have something to do here...");
  };

  const handleMultipleFunctions = () => {
    notify("You don't meet the requirements.");
    showProgressMessage();
  };

  const renderTravelButton = () => {
    if (
      (checkIfPlanetDiscovered() &&
        narration.unlocked.includes(destinationPlanet)) ||
      tour
    ) {
      return (
        <>
          {movement.currentMovePoints >= 5 && (
            <Link
              onClick={() => {
                dispatch(movementPointsSubtracted(5));
                dispatch(availablePlanetSet(destinationPlanet));
              }}
              to={`/${destinationPlanet}`}
            >
              <picture>
                <source srcSet={image_webp} type="image/webp" />
                <source srcSet={image_png} type="image/png" />
                <img
                  src={image_png}
                  type={"image/png"}
                  width={size}
                  height="auto"
                  alt="A giant flying rocket"
                />
              </picture>
            </Link>
          )}
          {movement.currentMovePoints < 5 && (
            <picture className="cursor-pointer">
              <source srcSet={image_webp} type="image/webp" />
              <source srcSet={image_png} type="image/png" />
              <img
                onClick={() =>
                  debounce(notify("5 move points required."), 1000)
                }
                src={image_png}
                type="image/png"
                width={size}
                height="auto"
                alt="A giant flying rocket"
              />
            </picture>
          )}
        </>
      );
    } else {
      return (
        <picture className="cursor-pointer">
          <source srcSet={image_webp} type="image/webp" />
          <source srcSet={image_png} type="image/png" />
          <img
            onClick={debounce(handleMultipleFunctions, 1000)}
            src={image_png}
            type="image/png"
            width={size}
            height="auto"
            alt="A giant flying rocket"
          />
        </picture>
      );
    }
  };

  return <>{renderTravelButton()}</>;
};

export default TravelImage;

TravelImage.propTypes = {
  requiredPlayerLvl: PropTypes.number,
  requiredRocketLvl: PropTypes.number,
  size: PropTypes.string,
  image_png: PropTypes.string,
  image_webp: PropTypes.string,
  destinationPlanet: PropTypes.string,
};
