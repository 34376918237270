import { get } from "lodash";
import toast from "react-hot-toast";

export const notify = (message) =>
  toast(message, {
    style: {
      background: "rgb(1, 9, 27)",
      color: "#ffff",
    },
  });

export const getStoreData = (path, initialData) => {
  const persistedStore = JSON.parse(localStorage.getItem("rocket-english"));
  const storeDestination = get(persistedStore, path);

  return storeDestination ? storeDestination : initialData;
};
