import React from "react";
import Planet from "./planet";
import PlaceBasic from "../universal/placeBasic";
import PlaceLaunchPad from "../universal/placeLaunchPad";
import PlaceUfo from "../universal/placeUfo";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import useCheckPlanetAccess from "../hooks/useCheckPlanetAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import {
  casino_png,
  casino_webp,
  quiz_png,
  quiz_webp,
  shop_png,
  shop_webp,
  rocket_png,
  rocket_webp,
  university_png,
  university_webp,
  ufo_png,
  ufo_webp,
  temple_png,
  temple_webp,
  mine_png,
  mine_webp,
  alchemy_png,
  alchemy_webp,
} from "./imagesIndex";

const Axios = () => {
  const { planets } = useSelector((state) => state.environment.planet);
  const { description, infrastructure, climate, inhabitants } = planets.find(
    (planet) => planet.name === "axios"
  );
  const dispatch = useDispatch();

  useChangeGameStatus("axios");
  useCheckPlanetAccess("axios");

  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <PlaceUfo
      onDragStart={handleDragStart}
      title={"Ufo"}
      img_webp={ufo_webp}
      img_png={ufo_png}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"School"}
      link={"quiz"}
      img_webp={quiz_webp}
      img_png={quiz_png}
      alt={"A thick red book. A school logo."}
      description={"You can test yourself and gain exp here."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Alchemist"}
      link={"alchemist"}
      img_webp={alchemy_webp}
      img_png={alchemy_png}
      alt={"Transparent bottle with a snake's eye and tail."}
      description={"Alchemists create potions that make wonders - literally."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Temple"}
      link={"temple"}
      img_webp={temple_webp}
      img_png={temple_png}
      alt={
        "A stone statue with precious stones in the shape of the sun god Helios."
      }
      description={
        "A quiet and peaceful place where you can pray for a good fortune."
      }
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Casino"}
      link={"casino"}
      img_webp={casino_webp}
      img_png={casino_png}
      alt={"The big pink neon sign with the word casino. A Casino logo."}
      description={"Be careful. Gambling is addictive."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Factory"}
      link={"factory"}
      img_webp={rocket_webp}
      img_png={rocket_png}
      alt={
        "A large robot and a space rocket, as well as night and stars in the background. Factory logo."
      }
      description={"Here you can upgrade your rocket."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Shop"}
      link={"shop"}
      img_webp={shop_webp}
      img_png={shop_png}
      alt={
        "A big black neon sign with red lettering that says open. A shop logo."
      }
      description={"You can buy a lot of useful things here."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Mine"}
      link={"mine"}
      img_webp={mine_webp}
      img_png={mine_png}
      alt={
        "A large chunk of rock with pink crystal fragments stuck in it. A mine logo."
      }
      description={"Here you can get credits and parts to upgrade your rocket."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"University"}
      link={"university"}
      img_webp={university_webp}
      img_png={university_png}
      alt={"A diploma with a blue ribbon. A university logo."}
      description={
        "The challenge here is to fill in the gaps in the text. It can also be your own text."
      }
    />,
    <PlaceLaunchPad
      onDragStart={handleDragStart}
      title={"Launch pad"}
      prevPlanet={"bathea"}
      nextPlanet={"desertia"}
      prevLabel={"Back to Bathea"}
      nextLabel={"Go to Desertia"}
      reqUserLvlNext={65}
      reqRocketLvlNext={1}
      reqUserLvlPrev={35}
      reqRocketLvlPrev={1}
    />,
  ];

  return (
    <div
      className="axios-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <Planet
        key={Math.random()}
        planet={"axios"}
        planetDescription={description}
        places={items}
        infrastructure={infrastructure}
        climate={climate}
        inhabitants={inhabitants}
      />
    </div>
  );
};

export default Axios;
