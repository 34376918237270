import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import WithIndicator from "../hoc/withIndicator";
import rocketIcon from "../../images/icons/rocket-icon.svg";
import "./linkFastTravel.css";

const LinkFastTravel = ({
  available,
  title,
  linkCallback,
  color,
  showIndicator,
}) => {
  const renderButton = () => {
    if (!available) {
      return (
        <React.Fragment>
          {title}
          <span className="desktop placeholder"></span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Link
            onClick={linkCallback}
            className={color}
            to={`/${title}`}
            style={{ textDecoration: "none" }}
          >
            {title}
          </Link>
          {showIndicator && (
            <span className="margin-top-05rem desktop">
              <img
                src={rocketIcon}
                type={"image/png"}
                width={"25em"}
                height="auto"
                alt="A giant flying rocket"
              />
            </span>
          )}
        </React.Fragment>
      );
    }
  };

  return renderButton();
};

export default WithIndicator(LinkFastTravel);

LinkFastTravel.propTypes = {
  available: PropTypes.bool,
  title: PropTypes.string,
  linkCallback: PropTypes.func,
  color: PropTypes.string,
  showIndicator: PropTypes.bool,
};
