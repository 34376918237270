import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStoreData } from "./utils";
import * as api from "../api";

const INITIAL_STATE = {
  mercenaries: [],
};

export const getMercenaries = createAsyncThunk(
  "npc/getMercenaries",
  async () => {
    try {
      const { data } = await api.fetchMercenaries();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const slice = createSlice({
  name: "npc",
  initialState: getStoreData("environment.npc", INITIAL_STATE),

  extraReducers: {
    [getMercenaries.pending]: (state) => {
      state.status = "loading";
    },
    [getMercenaries.fulfilled]: (state, action) => {
      state.mercenaries = action.payload;
      state.status = "success";
    },
    [getMercenaries.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default slice.reducer;
