import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStoreData } from "./utils";
import * as api from "../api";

const INITIAL_STATE = {
  planets: [],
};

export const getPlanets = createAsyncThunk("planets/getPlanets", async () => {
  try {
    const { data } = await api.fetchPlanets();
    return data;
  } catch (error) {
    console.log(error);
  }
});

const slice = createSlice({
  name: "planet",
  initialState: getStoreData("environment.planet", INITIAL_STATE),

  extraReducers: {
    [getPlanets.pending]: (state) => {
      state.status = "loading";
    },
    [getPlanets.fulfilled]: (state, action) => {
      state.planets = action.payload;
      state.status = "success";
    },
    [getPlanets.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default slice.reducer;
