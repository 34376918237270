import React from "react";
import PropTypes from "prop-types";
import "./thumbnail.css";

const Thumbnail = ({ styles, imageCategory, image, onClickAction, alt }) => {
  return (
    <picture className={styles}>
      <source
        type="image/webp"
        media="(min-width: 2560px)"
        srcSet={
          require(`../../images/${imageCategory}/1920/${image}.webp`).default
        }
      />
      <source
        type="image/jpg"
        media="(min-width: 2560px)"
        srcSet={
          require(`../../images/${imageCategory}/1920/${image}.jpg`).default
        }
      />
      <source
        type="image/webp"
        media="(max-width: 2560px)"
        srcSet={
          require(`../../images/${imageCategory}/1280/${image}.webp`).default
        }
      />
      <source
        type="image/jpg"
        media="(max-width: 2560px)"
        srcSet={
          require(`../../images/${imageCategory}/1280/${image}.jpg`).default
        }
      />
      <source
        type="image/webp"
        media="(max-width: 1280px)"
        srcSet={
          require(`../../images/${imageCategory}/960/${image}.webp`).default
        }
      />
      <source
        type="image/jpg"
        media="(max-width: 1280px)"
        srcSet={
          require(`../../images/${imageCategory}/960/${image}.jpg`).default
        }
      />
      <img
        className="shadow-bg"
        onClick={onClickAction}
        alt={`${alt}`}
        src={require(`../../images/${imageCategory}/1920/${image}.jpg`).default}
        type="image/jpg"
      />
    </picture>
  );
};

export default Thumbnail;

Thumbnail.propTypes = {
  styles: PropTypes.string,
  imageCategory: PropTypes.string,
  image: PropTypes.string,
  onClickAction: PropTypes.func,
  alt: PropTypes.string,
};
