import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  expAdded,
  freeItemsAdded,
  eventCounterIncremented,
} from "../../store/auth";
import { useForm } from "react-hook-form";
import ModalWindow from "../universal/modal";
import "./gapTest.css";

const GapTest = ({ resetMode, hideTest, text, ifPrize, mode, resetKey }) => {
  const { register, handleSubmit } = useForm();
  const formData = useRef();
  const [modalTrigger, setModalTrigger] = useState(false);
  const [summary, setSummary] = useState([]);
  const [summaryText, setSummaryText] = useState([]);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setModalTrigger(!modalTrigger);
  };

  const gapTestPrize = (multiplier) => {
    dispatch(expAdded(500 * multiplier));
    dispatch(
      freeItemsAdded({
        credits: 500 * multiplier,
        steel: 10 * multiplier,
        aluminum: 5 * multiplier,
      })
    );
  };

  const handleSubmitUserData = (data) => {
    let counter = 0;
    for (let [index, elem] of Object.entries(formData.current)) {
      if (elem.word === data[index]) {
        elem.guessed = true;
        counter++;
      }
    }

    makeSummaryTextColored(text, formData.current);

    if (ifPrize) {
      if (counter) {
        if (counter === Object.keys(formData.current).length) {
          dispatch(eventCounterIncremented("fillTheGapsCompleted"));
        }
        setSummary([
          ["exp", 50 * counter],
          ["credits", 50 * counter],
          ["steel", 5 * counter],
          ["aluminum", 1 * counter],
        ]);
      }
      gapTestPrize(counter);
    }
    toggleModal();
  };

  const renderSummary = () => {
    if (summary.length !== 0) {
      return summary.map((element, id) => (
        <li key={id + 1000}>
          +{element[1]} {element[0]}{" "}
        </li>
      ));
    } else {
      return mode !== "user" && <p>Keep learning...</p>;
    }
  };

  const makeSummaryTextColored = (text, answers) => {
    const words = text.split(" ");
    const splitted = splitText(text);

    for (let [id, answer] of Object.entries(answers)) {
      if (answer.guessed) {
        splitted[answer.id] = (
          <li key={id + 10000} className="win">
            {words[answer.id]}
          </li>
        );
      } else {
        splitted[answer.id] = (
          <li key={id + 10000} className="lose">
            {words[answer.id]}
          </li>
        );
      }
    }
    setSummaryText(splitted);
  };

  const onSubmit = (data) => handleSubmitUserData(data);

  const splitText = (text) => {
    const words = {};
    const splittedText = text.split(" ");
    const filtered = [];

    for (let [index, word] of splittedText.entries()) {
      if (
        word === "the" ||
        word === "The" ||
        word === "a" ||
        word === "A" ||
        word === "an" ||
        word === "An"
      ) {
        filtered.push(
          <input
            type="text"
            {...register(String(index), {
              required: true,
              minLength: 1,
              maxLength: 3,
              pattern: /^[A-Za-z]+$/i,
            })}
          />
        );
        words[index] = { id: index, word: word, guessed: false };
      } else {
        filtered.push(word);
      }
    }

    formData.current = words;
    return filtered.map((elem, id) => <li key={id}>{elem}</li>);
  };

  return (
    <div id="gapTest" className="padding border">
      <article className="testMenu-activities-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>{splitText(text)}</ul>
          <button type="submit" className="button small">
            Submit
          </button>
        </form>
      </article>
      <ModalWindow
        content={
          <>
            <ul className="win">{renderSummary()}</ul>
            <ul className="list-horizontal modal-description">
              {summaryText.map((elem) => elem)}
            </ul>
          </>
        }
        contentLabel="Gap Test"
        isOpen={modalTrigger}
        onRequestClose={() => {
          resetMode(null);
          hideTest(false);
          resetKey(Math.random());
        }}
      />
    </div>
  );
};

export default GapTest;

GapTest.propTypes = {
  resetMode: PropTypes.bool,
  hideTest: PropTypes.bool,
  text: PropTypes.string,
  ifPrize: PropTypes.bool,
  mode: PropTypes.string,
  resetKey: PropTypes.number,
};
