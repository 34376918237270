import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import { useSelector, useDispatch } from "react-redux";
import FavoritesCard from "../../components/universal/articleCard";
import { navbarBgChanged } from "../../store/game";
import renders from "../../utils/renders";
import LinkButton from "../universal/linkButton";
import "react-alice-carousel/lib/alice-carousel.css";
import "./favorites.css";

const Favorites = () => {
  const { currentPlanet, favorites } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          favorites: [],
        }
  );
  const favs = useRef([]);

  const dispatch = useDispatch();

  favs.current = favorites.map((element) => (
    <FavoritesCard title={element.word} description={element.definition} />
  ));

  return (
    <section
      className="favorites-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <div id="favorites">
        <section className="favorites-container main-background border border-radius padding margin-block-favorites-container">
          <div className="padding border">
            <h3>Favorites</h3>
            <article className="favorites-activities-container">
              {favs.current.length !== 0 && (
                <AliceCarousel
                  disableDotsControls
                  controlsStrategy={"responsive"}
                  responsive={renders.carousel}
                  keyboardNavigation
                  infinite
                  items={favs.current}
                />
              )}
              {favs.current.length === 0 && (
                <p className="place-description">
                  Nothing here yet - words with descriptions that you can save
                  while completing challenges appear here.
                </p>
              )}
            </article>
            <LinkButton destination={currentPlanet} title={"go back"} />
          </div>
        </section>
      </div>
    </section>
  );
};

export default Favorites;
