import React from "react";
import Planet from "./planet";
import PlaceBasic from "../universal/placeBasic";
import PlaceLaunchPad from "../universal/placeLaunchPad";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import useCheckPlanetAccess from "../hooks/useCheckPlanetAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import {
  quiz_png,
  quiz_webp,
  shop_png,
  shop_webp,
  university_png,
  university_webp,
  mine_png,
  mine_webp,
  smuggler_png,
  smuggler_webp,
  bar_png,
  bar_webp,
} from "./imagesIndex";

const Therion = () => {
  const { planets } = useSelector((state) => state.environment.planet);
  const { description, infrastructure, climate, inhabitants } = planets.find(
    (planet) => planet.name === "therion"
  );
  const dispatch = useDispatch();

  useChangeGameStatus("therion");
  useCheckPlanetAccess("therion");

  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Smuggler"}
      link={"smuggler"}
      img_webp={smuggler_webp}
      img_png={smuggler_png}
      alt={
        "A scary looking bottle with a skull on the label, the symbol of the smuggler."
      }
      description={
        "He will give away the most valuable thing for a little help."
      }
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Shop"}
      link={"shop"}
      img_webp={shop_webp}
      img_png={shop_png}
      alt={
        "A big black neon sign with red lettering that says open. A shop logo."
      }
      description={"You can buy a lot of useful things here."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Mine"}
      link={"mine"}
      img_webp={mine_webp}
      img_png={mine_png}
      alt={
        "A large chunk of rock with pink crystal fragments stuck in it. A mine logo."
      }
      description={"Here you can get credits and parts to upgrade your rocket."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"University"}
      link={"university"}
      img_webp={university_webp}
      img_png={university_png}
      alt={"A diploma with a blue ribbon. A university logo."}
      description={
        "The challenge here is to fill in the gaps in the text. It can also be your own text."
      }
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"School"}
      link={"quiz"}
      img_webp={quiz_webp}
      img_png={quiz_png}
      alt={"A thick red book. A school logo."}
      description={"You can test yourself and gain exp here."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Bar"}
      link={"bar"}
      img_webp={bar_webp}
      img_png={bar_png}
      alt={"A Shiny black and pink drink neon sign. A bar logo."}
      description={
        "A place for gossip and meetings. You can also hire mercenaries here."
      }
    />,
    <PlaceLaunchPad
      onDragStart={handleDragStart}
      title={"Launch pad"}
      prevPlanet={"crion"}
      nextPlanet={"crystalia"}
      prevLabel={"Back to Crion"}
      nextLabel={"Go to Crystalia"}
      reqUserLvlNext={10}
      reqRocketLvlNext={1}
      reqUserLvlPrev={1}
      reqRocketLvlPrev={1}
    />,
  ];

  return (
    <div
      className="therion-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <Planet
        key={Math.random()}
        planet={"therion"}
        planetDescription={description}
        places={items}
        infrastructure={infrastructure}
        climate={climate}
        inhabitants={inhabitants}
      />
    </div>
  );
};

export default Therion;
