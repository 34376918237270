import React from "react";
import PropTypes from "prop-types";
import { notify } from "../../store/utils";
import LinkButton from "./linkButton";
import { useDispatch, useSelector } from "react-redux";
import { availablePlanetSet, movementPointsSubtracted } from "../../store/auth";
import "./travelButton.css";

const TravelButton = ({
  requiredPlayerLvl,
  requiredRocketLvl,
  label,
  destinationPlanet,
}) => {
  const dispatch = useDispatch();

  const { narration, movement, tour, characterLvl, rocketLvl } = useSelector(
    (state) =>
      state.user.auth.currentUser
        ? state.user.auth.currentUser.progress
        : {
            narration: null,
            movement: null,
            tour: null,
            characterLvl: null,
            rocketLvl: null,
          }
  );

  const checkIfPlanetDiscovered = () => {
    return characterLvl >= requiredPlayerLvl && rocketLvl >= requiredRocketLvl
      ? true
      : false;
  };

  const lockedButtonLabel = () => {
    if (characterLvl < requiredPlayerLvl && rocketLvl < requiredRocketLvl) {
      return `LEVEL: ${requiredPlayerLvl}, ROCKET: ${requiredRocketLvl}`;
    } else if (characterLvl < requiredPlayerLvl) {
      return `LEVEL: ${requiredPlayerLvl}`;
    } else if (rocketLvl < requiredRocketLvl) {
      return `ROCKET: ${requiredRocketLvl}`;
    }
  };

  const showProgressMessage = () => {
    if (!narration.unlocked.includes(destinationPlanet))
      notify("You have something to do here...");
  };

  const renderTravelButton = () => {
    if (
      (checkIfPlanetDiscovered() &&
        narration.unlocked.includes(destinationPlanet)) ||
      tour
    ) {
      return (
        <>
          {movement.currentMovePoints >= 5 && (
            <LinkButton
              linkCallback={() => {
                dispatch(movementPointsSubtracted(5));
                dispatch(availablePlanetSet(destinationPlanet));
              }}
              destination={destinationPlanet}
              title={label}
            />
          )}
          {movement.currentMovePoints < 5 && (
            <LinkButton
              empty
              emptyCallback={() => notify("5 move points required.")}
              title={label}
            />
          )}
        </>
      );
    } else {
      return (
        <LinkButton
          empty
          emptyCallback={() => {
            notify("You don't meet the requirements.");
            showProgressMessage();
          }}
          title={lockedButtonLabel()}
        />
      );
    }
  };

  return <>{renderTravelButton()}</>;
};

export default TravelButton;

TravelButton.propTypes = {
  requiredPlayerLvl: PropTypes.number,
  requiredRocketLvl: PropTypes.number,
  label: PropTypes.string,
  destinationPlanet: PropTypes.string,
};
