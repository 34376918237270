import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import useCheckPlanetAccess from "../hooks/useCheckPlanetAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import Planet from "./planet";
import PlaceBasic from "../universal/placeBasic";
import PlaceLaunchPad from "../universal/placeLaunchPad";
import {
  quiz_png,
  quiz_webp,
  shop_png,
  shop_webp,
  rocket_png,
  rocket_webp,
  mine_png,
  mine_webp,
  smuggler_png,
  smuggler_webp,
} from "./imagesIndex";

const Xillon = () => {
  const { planets } = useSelector((state) => state.environment.planet);
  const { description, infrastructure, climate, inhabitants } = planets.find(
    (planet) => planet.name === "xillon"
  );
  const dispatch = useDispatch();

  useChangeGameStatus("xillon");
  useCheckPlanetAccess("xillon");

  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Shop"}
      link={"shop"}
      img_webp={shop_webp}
      img_png={shop_png}
      alt={
        "A big black neon sign with red lettering that says open. A shop logo."
      }
      description={"You can buy a lot of useful things here."}
    />,

    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Mine"}
      link={"mine"}
      img_webp={mine_webp}
      img_png={mine_png}
      alt={
        "A large chunk of rock with pink crystal fragments stuck in it. A mine logo."
      }
      description={"Here you can get credits and parts to upgrade your rocket."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Factory"}
      link={"factory"}
      img_webp={rocket_webp}
      img_png={rocket_png}
      alt={
        "A large robot and a space rocket, as well as night and stars in the background. Factory logo."
      }
      description={"Here you can upgrade your rocket."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Smuggler"}
      link={"smuggler"}
      img_webp={smuggler_webp}
      img_png={smuggler_png}
      alt={
        "A scary looking bottle with a skull on the label, the symbol of the smuggler."
      }
      description={
        "He will give away the most valuable thing for a little help."
      }
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"School"}
      link={"quiz"}
      img_webp={quiz_webp}
      img_png={quiz_png}
      alt={"A thick red book. A school logo."}
      description={"You can test yourself and gain exp here."}
    />,
    <PlaceLaunchPad
      onDragStart={handleDragStart}
      title={"Launch pad"}
      prevPlanet={"desertia"}
      nextPlanet={"centuria"}
      prevLabel={"Back to Desertia"}
      nextLabel={"Go to Centuria"}
      reqUserLvlNext={100}
      reqRocketLvlNext={1}
      reqUserLvlPrev={65}
      reqRocketLvlPrev={1}
    />,
  ];

  return (
    <div
      className="xillon-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <Planet
        key={Math.random()}
        planet={"xillon"}
        planetDescription={description}
        places={items}
        infrastructure={infrastructure}
        climate={climate}
        inhabitants={inhabitants}
      />
    </div>
  );
};

export default Xillon;
