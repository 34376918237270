import {
  Xillon,
  Centuria,
  Crion,
  Therion,
  Crystalia,
  Thalia,
  Bathea,
  Axios,
  Desertia,
} from "./planetIndex";
import {
  Welcome,
  NotFound,
  Factory,
  Shop,
  Inventory,
  Favorites,
  Controller,
  Casino,
  Mine,
  Ufo,
  Bar,
  Temple,
  Smuggler,
  Alchemist,
  TestMenu,
  Trophies,
  Preferences,
  Auth,
  PasswordReset,
  Help,
} from "./placesIndex";

import ProtectedRoute from "./components/universal/protectedRoute";
import { Route, Redirect } from "react-router";

export const routes = [
  <ProtectedRoute key="/space" path="/space" exact component={NotFound} />,
  <ProtectedRoute key="/ufo" path="/ufo" component={Ufo} />,
  <ProtectedRoute key="/factory" path="/factory" component={Factory} />,
  <ProtectedRoute key="/casino" path="/casino" component={Casino} />,
  <ProtectedRoute key="/quiz" path="/quiz" component={Controller} />,
  <ProtectedRoute key="/shop" path="/shop" component={Shop} />,
  <ProtectedRoute key="/bar" path="/bar" component={Bar} />,
  <ProtectedRoute key="/mine" path="/mine" component={Mine} />,
  <ProtectedRoute key="/temple" path="/temple" component={Temple} />,
  <ProtectedRoute key="/smuggler" path="/smuggler" component={Smuggler} />,
  <ProtectedRoute key="/alchemist" path="/alchemist" component={Alchemist} />,
  <ProtectedRoute key="/favorites" path="/favorites" component={Favorites} />,
  <ProtectedRoute key="/university" path="/university" component={TestMenu} />,
  <ProtectedRoute key="/inventory" path="/inventory" component={Inventory} />,
  <ProtectedRoute
    key="/preferences"
    path="/preferences"
    component={Preferences}
  />,
  <ProtectedRoute key="/help" path="/help" component={Help} />,
  <ProtectedRoute key="/trophies" path="/trophies" component={Trophies} />,
  <ProtectedRoute key="/xillon" path="/xillon" component={Xillon} />,
  <ProtectedRoute key="/centuria" path="/centuria" component={Centuria} />,
  <ProtectedRoute key="/crion" path="/crion" component={Crion} />,
  <ProtectedRoute key="/therion" path="/therion" component={Therion} />,
  <ProtectedRoute key="/crystalia" path="/crystalia" component={Crystalia} />,
  <ProtectedRoute key="/thalia" path="/thalia" component={Thalia} />,
  <ProtectedRoute key="/bathea" path="/bathea" component={Bathea} />,
  <ProtectedRoute key="/axios" path="/axios" component={Axios} />,
  <ProtectedRoute key="/desertia" path="/desertia" component={Desertia} />,
  <ProtectedRoute
    key="/passwordreset"
    path="/passwordreset"
    component={PasswordReset}
    notLoggedAccessOnly
  />,
  <Route key="/" path="/" exact component={Welcome} />,
  <ProtectedRoute
    key="/auth"
    path="/auth"
    component={Auth}
    notLoggedAccessOnly
  />,
  <Redirect key="/space" to="/space" />,
];
