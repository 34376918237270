import React, { useState, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { RiLock2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "../universal/visibilityIcon";
import ModalMenu from "../universal/modalMenu";
import { deleteUser } from "../../store/auth";
import { updateUserAccount, saveGame, logout } from "../../store/auth";
import { notify } from "../../store/utils";
import debounce from "../../utils/debounce";
import decode from "jwt-decode";

const AccountEditor = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [user] = useState(
    JSON.parse(localStorage.getItem("rocket-english-profile"))
  );

  const currentUser = useSelector((state) =>
    state.user.auth.currentUser ? state.user.auth.currentUser : ""
  );

  const token = user?.credential;
  const decodedToken = decode(token);

  const dispatch = useDispatch();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const {
    register: registerEditData,
    handleSubmit: handleRegisterEditData,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      username: currentUser.name,
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      email: currentUser.email,
    },
  });

  const handleEditData = useCallback(
    (data) => {
      if (data.oldPassword && data.newPassword === data.confirmNewPassword) {
        dispatch(updateUserAccount({ id: user.clientId, userData: data }));
      } else if (
        !data.oldPassword &&
        !data.newPassword &&
        !data.confirmNewPassword
      ) {
        dispatch(updateUserAccount({ id: user.clientId, userData: data }));
      } else notify("Something is missing");
    },
    [dispatch, user.clientId]
  );

  const debouncedHandleEditData = useMemo(
    () => debounce((data) => handleEditData(data), 400),
    [handleEditData]
  );

  return (
    <article className="preferences-form-container">
      {user?.credential && (
        <>
          {decodedToken.iss !== "https://accounts.google.com" && (
            <form
              className="preferences-form"
              onSubmit={handleRegisterEditData(debouncedHandleEditData)}
            >
              <RiLock2Line size={"2rem"} />
              <input
                type="text"
                placeholder={"User Name"}
                {...registerEditData("username", {
                  minLength: 3,
                  maxLength: 30,
                })}
              />
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder={"Current password"}
                {...registerEditData("oldPassword", {
                  minLength: 8,
                  maxLength: 25,
                })}
              />
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder={"New password"}
                {...registerEditData("newPassword", {
                  minLength: 8,
                  maxLength: 25,
                })}
              />
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder={"Confirm new password"}
                {...registerEditData("confirmNewPassword", {
                  minLength: 8,
                  maxLength: 25,
                })}
              />
              <VisibilityIcon
                condition={isPasswordVisible}
                toggler={() => setIsPasswordVisible(!isPasswordVisible)}
              />
              <input
                type="text"
                placeholder={"Email"}
                {...registerEditData("email", {
                  minLength: 5,
                  maxLength: 40,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              <button className="button menu-button" type="submit">
                Save changes
              </button>
              <button
                type="button"
                className="button green menu-button margin-top-2rem"
                onClick={() => dispatch(saveGame(currentUser.progress))}
              >
                Save game
              </button>
              <button
                type="button"
                className="button red menu-button"
                onClick={() => {
                  dispatch(logout());
                }}
              >
                Logout
              </button>
              <div className="line margin-top-2rem" />
              <button
                type="button"
                className="button warning menu-button margin-top-1rem"
                onClick={() => toggleModal()}
              >
                Delete Account
              </button>
            </form>
          )}
        </>
      )}
      <ModalMenu
        content="Your account will be deleted"
        contentLabel="Delete account confirmation"
        isOpen={showModal}
        onRequestClose={toggleModal}
        toggleModal={toggleModal}
        confirmActionCallback={() =>
          dispatch(deleteUser({ id: user.clientId }))
        }
      />
    </article>
  );
};

export default AccountEditor;
