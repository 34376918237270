import React from "react";
import DialogueMenu from "../universal/dialogueMenu";
import useCheckPlaceAccess from "../hooks/useCheckPlaceAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import dialogues from "../../utils/dialogues";
import { navbarBgChanged } from "../../store/game";
import { rocketUpgraded, itemsGiven } from "../../store/auth";
import { useDispatch, useSelector } from "react-redux";
import factory_webp from "../../images/rocket.webp";
import factory_png from "../../images/rocket.png";
import upgrades from "../../utils/rocket";
import LinkButton from "../universal/linkButton";
import HeaderWithLogo from "../universal/headerWithLogo";
import Header from "../universal/header";
import "./factory.css";
import "../../App.css";

export const Factory = () => {
  useCheckPlaceAccess("factory");
  useChangeGameStatus();

  const dispatch = useDispatch();

  const { currentPlanet, inventory, rocketLvl } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          inventory: [],
          rocketLvl: 0,
        }
  );

  const placeDescription =
    "The robotic brigade is constantly building and repairing damaged spacecraft. They are also happy to upgrade the space rocket.";

  const handleUpgradeRocket = (req) => {
    dispatch(itemsGiven({ give: req }));
    dispatch(rocketUpgraded());
  };

  const renderUpgradeButton = (requirements) => {
    for (let [item, amount] of Object.entries(requirements)) {
      if (amount > inventory[item]) {
        return (
          <button style={{ textDecoration: "none" }} className="button small">
            Not enough items
          </button>
        );
      }
    }
    return (
      <button
        onClick={() => handleUpgradeRocket(requirements)}
        style={{ textDecoration: "none" }}
        className="button small"
      >
        Upgrade to level {rocketLvl + 1}
      </button>
    );
  };

  const renderRequirements = () => {
    const nextLvl = upgrades[rocketLvl + 1];
    const all = [];

    for (let [item, amount] of Object.entries(nextLvl)) {
      all.push(item);
      all.push(amount);
    }
    return all.join(" ");
  };

  return (
    <main
      id="factory-container"
      className="factory-wrapper flex-auto main-wrapper"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <section id="factory" className="header-container">
        <HeaderWithLogo
          headerSize={"h2"}
          header={"factory"}
          text={placeDescription}
          webp={factory_webp}
          png={factory_png}
          size={"150em"}
          alt={
            "A large robot and a space rocket, as well as night and stars in the background. A factory logo."
          }
        />
        <section>
          <Header headerSize={"h3"} header={"upgrade"} underline />
          <article className="section-split margin-bottom-2rem">
            <article className="align-self-flex-start">
              <Header headerSize={"h4"} header={"build"} />
              {rocketLvl === 5 && (
                <p>The rocket is now at its maximum level - {rocketLvl}</p>
              )}
              {rocketLvl < 5 && <p>Current rocket level - {rocketLvl}</p>}
              {rocketLvl < 5 && <p>Next level cost - {renderRequirements()}</p>}
              {rocketLvl < 5 && renderUpgradeButton(upgrades[rocketLvl + 1])}
            </article>
          </article>
        </section>
        <section>
          <Header headerSize={"h3"} header={"talk"} underline />
          <article>
            <Header headerSize={"h4"} header={"engineer"} />
            {dialogues[currentPlanet].length !== 0 && (
              <DialogueMenu place={"factory"} />
            )}
          </article>
        </section>
        <LinkButton destination={currentPlanet} title={"walk away"} />
      </section>
    </main>
  );
};
export default Factory;
