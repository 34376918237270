import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { favoriteItemRemoved } from "../../store/auth";

const FavoritesCard = ({ title, description }) => {
  const dispatch = useDispatch();

  return (
    <article className="padding-places border carousel-card scroll">
      <div className="fav-title">
        <h4>{title}</h4>
        <i
          onClick={() => dispatch(favoriteItemRemoved(title))}
          class="fas fa-times fav-del-button cursor-pointer"
        ></i>
      </div>
      <p className="align-center">{description}</p>
    </article>
  );
};

export default FavoritesCard;

FavoritesCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
