import React, { useState } from "react";
import DialogueMenu from "../universal/dialogueMenu";
import useCheckPlaceAccess from "../hooks/useCheckPlaceAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import dialogues from "../../utils/dialogues";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import { getDeadMercenaries } from "../../store/helpers";
import { creditsSubtracted, mercenaryResurrected } from "../../store/auth";
import HeaderWithLogo from "../universal/headerWithLogo";
import LinkButton from "../universal/linkButton";
import temple_webp from "../../images/temple.webp";
import temple_png from "../../images/temple.png";
import Header from "../universal/header";
import ModalWindow from "../universal/modal";
import "../planets/planet.css";
import "./temple.css";
import { notify } from "../../store/utils";

const Temple = () => {
  useCheckPlaceAccess("temple");
  useChangeGameStatus();

  const dispatch = useDispatch();

  const { currentPlanet, inventory, mercenaries } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          inventory: null,
          mercenaries: { dead: [] },
        }
  );

  const { mercenaries: npcForHire } = useSelector(
    (state) => state.environment.npc
  );

  const credits = inventory ? inventory.credits : 0;
  const [modalTrigger, setModalTrigger] = useState(false);
  const [summary, setSummary] = useState([]);
  const toggleModal = () => {
    setModalTrigger(!modalTrigger);
  };

  const placeDescription =
    "In the temple, pilgrims make donations and pray for good fate and happiness. The monks perform rituals and make sure that the altars do not run out of candles. When traveling through space, it is worth taking care of the favor of providence from time to time.";

  const setUpDonation = (e) => {
    e.preventDefault();
    const deadMercenaries = getDeadMercenaries(mercenaries, npcForHire);

    const inputElement = document.getElementById("donationFormInput");
    const inputValue = inputElement.value;

    if (inputValue <= 0) {
      notify("Give a positive number");
    } else if (inputValue <= credits) {
      if (deadMercenaries.length !== 0) {
        const loseOrWin = ["loser", "loser", "loser", "loser", "winner"];
        const result = loseOrWin[Math.floor(Math.random() * loseOrWin.length)];
        dispatch(creditsSubtracted(inputValue));
        if (result === "loser") {
          setSummary(<p>Keep praying...</p>);
          toggleModal();
        } else {
          const randomMercenary =
            deadMercenaries[Math.floor(Math.random() * deadMercenaries.length)];
          dispatch(mercenaryResurrected(randomMercenary._id));

          setSummary(
            <div>
              <span>Your prayers have been listened...</span>
              <p className="win margin-top-05">{`${randomMercenary.name} is back to life`}</p>
            </div>
          );
          toggleModal();
        }
      } else {
        dispatch(creditsSubtracted(inputValue));
        setSummary(
          <p className="win margin-top-05">
            Fortunately, all of your mercenaries are alive, but we would
            appreciate your donation.
          </p>
        );
        toggleModal();
      }
    } else {
      notify("Not enaugh credits");
    }
  };

  return (
    <main
      className="temple-wrapper flex-auto main-wrapper"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <section id="temple">
        <section className="temple-header-container">
          <HeaderWithLogo
            headerSize={"h2"}
            header={"temple"}
            text={placeDescription}
            webp={temple_webp}
            png={temple_png}
            size={"150em"}
            alt={
              "A stone statue with precious stones in the shape of the sun god Helios."
            }
          />
          <section>
            <Header headerSize={"h3"} header={"pray"} underline />
            <article className="section-split margin-bottom-2rem">
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"altar"} />
                <form id="submitDepositForm" onSubmit={setUpDonation}>
                  <input
                    type="number"
                    min="1"
                    max="1000000"
                    required
                    id="donationFormInput"
                  />
                </form>
                <button className="button small" onClick={setUpDonation}>
                  Make a donation
                </button>
              </article>
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"memory stone"} />
                <div>
                  <ul>
                    {getDeadMercenaries(mercenaries, npcForHire).map(
                      (mercenary) => (
                        <li key={mercenary._id}>{mercenary.name}</li>
                      )
                    )}
                  </ul>
                </div>
              </article>
            </article>
          </section>
          <section>
            <Header headerSize={"h3"} header={"talk"} underline />
            <article>
              <Header headerSize={"h4"} header={"monk"} />
              {dialogues[currentPlanet].length !== 0 && (
                <DialogueMenu place={"temple"} />
              )}
            </article>
          </section>
          <LinkButton destination={currentPlanet} title={"walk away"} />
        </section>
        <ModalWindow
          content={summary}
          contentLabel={"Temple"}
          isOpen={modalTrigger}
          onRequestClose={toggleModal}
        />
      </section>
    </main>
  );
};

export default Temple;
