import React from "react";
import PropTypes from "prop-types";
import WithIndicator from "../hoc/withIndicator";
import "./trophy.css";

const Trophy = ({
  collected,
  webp,
  webp_locked,
  png,
  png_locked,
  reward,
  showIndicator,
  name,
  size,
  alt,
}) => {
  return (
    <div className="trophy">
      <picture className={"trophy"}>
        <source srcSet={collected ? webp : webp_locked} type="image/webp" />
        <source srcSet={collected ? png : png_locked} type="image/png" />
        <img
          src={collected ? png : png_locked}
          type="image/png"
          width={size}
          height="auto"
          alt={alt}
        />
      </picture>
      {!showIndicator && name}
      {showIndicator && `REWARD: ${reward} EXP`}
    </div>
  );
};

export default WithIndicator(Trophy);

WithIndicator.propTypes = {
  collected: PropTypes.string,
  webp: PropTypes.string,
  webp_locked: PropTypes.string,
  png: PropTypes.string,
  png_locked: PropTypes.string,
  reward: PropTypes.string,
  showIndicator: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.string,
  alt: PropTypes.string,
};
