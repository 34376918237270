import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStoreData } from "./utils";
import * as api from "../api";

const INITIAL_STATE = {
  trophies: [],
};

export const getTrophies = createAsyncThunk(
  "trophies/getTrophies",
  async () => {
    try {
      const { data } = await api.fetchTrophies();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const slice = createSlice({
  name: "trophy",
  initialState: getStoreData("environment.trophy", INITIAL_STATE),

  extraReducers: {
    [getTrophies.pending]: (state) => {
      state.status = "loading";
    },
    [getTrophies.fulfilled]: (state, action) => {
      state.trophies = action.payload;
      state.status = "success";
    },
    [getTrophies.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default slice.reducer;
