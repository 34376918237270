/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Footer from "../footer";
import { useDispatch, useSelector } from "react-redux";
import { narration as plotNarration } from "../../utils/dialogues";
import Modal from "react-modal";
import Thumbnail from "../universal/thumbnail";
import {
  freeItemsAdded,
  tourModeSwitched,
  movementPointsSet,
  availablePlanetSet,
  currentPlanetSet,
  newGamePlusStarted,
  resetUserProgress,
  gamePauseStatusSwitched,
  newGameStatusSwitched,
} from "../../store/auth";
import { checkIfNarrationAvailable } from "../../store/helpers";
import { navbarBgChanged } from "../../store/game";
import ModalWindow from "../universal/modal";
import { modalStyle } from "../../utils/renders";
import "./welcome.css";

const Welcome = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [narrationModalTrigger, setNarrationModalTrigger] = useState(false);

  const { currentUser } = useSelector((state) => state.user.auth);
  const currentPlanet = currentUser ? currentUser.progress.currentPlanet : null;
  const narration = currentUser ? currentUser.progress.narration : null;
  const newGame = currentUser ? currentUser.progress.newGame : true;
  const gameFinished = currentUser ? currentUser.progress.gameFinished : false;

  const { discovered } = currentUser
    ? currentUser.progress.planets
    : { discovered: [] };

  useEffect(() => {
    if (gameFinished) {
      if (checkIfNarrationAvailable(narration, currentPlanet)) {
        setNarrationModalTrigger(!narrationModalTrigger);
      }
    }
  }, [currentPlanet, narration]);

  useEffect(() => {
    if (currentUser) {
      dispatch(gamePauseStatusSwitched(true));
      if (!discovered.includes("crion")) {
        dispatch(currentPlanetSet("menu"));
      }
    }
    return () => {
      dispatch(navbarBgChanged(0));
    };
  }, [currentUser, discovered, dispatch]);

  const handleDisplayContent = () => {
    const ifAvailable = checkIfNarrationAvailable(narration, currentPlanet);
    if (ifAvailable) {
      return plotNarration[currentPlanet][0].content.map((contentType) => (
        <li key={contentType.id}>{contentType.text}</li>
      ));
    }
  };

  const handleDisplayTitle = () => {
    const ifAvailable = checkIfNarrationAvailable(narration, currentPlanet);
    if (ifAvailable) {
      return plotNarration[currentPlanet][0].title;
    }
  };

  const placeDescription =
    "Get to know an extraordinary galaxy by traveling, having fun and taking on challenges. Discover an amazing world and an amazing story while learning and practicing your English.";

  const renderResetProgress = () => {
    if (discovered.includes("crion")) {
      return (
        <button onClick={() => toggleModal()} className="button large">
          New game
        </button>
      );
    }
  };

  const resetProgress = () => {
    dispatch(resetUserProgress());
    toggleModal();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const startTour = () => {
    dispatch(
      freeItemsAdded({
        credits: 44500,
        steel: 500,
        word: 99,
        stardust: 500,
        aluminum: 500,
        crystal: 500,
        moonshine: 5,
      })
    );
    dispatch(newGameStatusSwitched(false));
    dispatch(tourModeSwitched(true));
    dispatch(availablePlanetSet("crion"));
    dispatch(currentPlanetSet("crion"));
    dispatch(movementPointsSet({ current: 150, max: 150 }));
    setRedirect("crion");
  };

  const startGame = () => {
    dispatch(newGameStatusSwitched(false));
    dispatch(availablePlanetSet("crion"));
    dispatch(currentPlanetSet("crion"));
    setRedirect("crion");
  };

  const renderNewGame = () => {
    return (
      <>
        {currentUser && newGame && (
          <>
            <button
              className="button large colors-inverted"
              onClick={() => startGame()}
            >
              I want a challenge [Play]
            </button>
            <button onClick={() => startTour()} className="button large green">
              I'm just a tourist [Free Tour]
            </button>
          </>
        )}
        {!currentUser && newGame && (
          <>
            <button
              data-testid="startGame"
              className="button large colors-inverted"
              onClick={() => setRedirect("auth")}
            >
              <i className="fas fa-rocket"></i> Get started
            </button>
            <button className="button large">
              <a className="no-decoration" href="#gallery">
                Learn more
              </a>
            </button>
          </>
        )}
      </>
    );
  };

  const renderContinueGame = () => {
    if (discovered.includes("crion")) {
      return (
        <Link
          data-testid="continueGame"
          onClick={() => dispatch(availablePlanetSet(currentPlanet))}
          to={`/${currentPlanet}`}
          style={{ textDecoration: "none" }}
        >
          <button className="button large">Continue</button>
        </Link>
      );
    }
  };

  return (
    <div
      className="welcome-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      {redirect && <Redirect to={`/${redirect}`} />}
      <section id="welcome" className="welcome-container padding">
        <div>
          <article className="padding-places">
            <div id="intro">
              <header className="intro-split header-container">
                <article className="content">
                  <h2>explore</h2>
                  <hr className="underline" />
                  <p className="planet-description">{placeDescription}</p>
                </article>
                <article className="content menu-title">
                  <div className="places-header">
                    <h2>the galaxy</h2>
                    <hr className="underline-places" />
                  </div>
                  <nav className="menu-nav">
                    {newGame && renderNewGame()}
                    {!newGame && renderResetProgress()}
                    {!newGame && renderContinueGame()}
                  </nav>
                </article>
              </header>
            </div>
            <section id="gallery" className="planet-split padding-1rem">
              <article className="content menu-title">
                <div className="places-header">
                  <h2 data-testid="adventureHeader">adventure</h2>
                  <hr className="underline-places" />
                </div>
              </article>
              <article></article>
            </section>
            <section className="planet-info-container gallery-split">
              <article className="flex-centered">
                <h4>play</h4>
                <Thumbnail
                  styles={"thumbnail"}
                  imageCategory={"planet-images"}
                  image={"thumbnail-light"}
                  alt={"Explore a fantastic galaxy full of interesting places."}
                />
                <p className="width-80">
                  Explore a fantastic galaxy full of interesting places.
                </p>
              </article>
              <article className="flex-centered">
                <h4>learn</h4>
                <Thumbnail
                  styles={"thumbnail"}
                  imageCategory={"planet-images"}
                  image={"thumbnail-learn"}
                  alt={"Take on language challenges and earn rewards."}
                />
                <p className="width-80">
                  Take on language challenges and earn rewards.
                </p>
              </article>
              <article className="flex-centered">
                <h4>explore</h4>
                <Thumbnail
                  styles={"thumbnail"}
                  imageCategory={"planet-images"}
                  image={"thumbnail-rocket"}
                  alt={"Explore the planets with an upgradeable rocket."}
                />
                <p className="width-80">
                  Explore the planets with an upgradeable rocket.
                </p>
              </article>
            </section>
            <a href="#welcome">
              <i className="rotate180deg nav-arrow padding-2rem far fa-arrow-alt-circle-down fa-2x"></i>
            </a>
            <Modal
              id="modal"
              closeTimeoutMS={500}
              style={modalStyle}
              isOpen={showModal}
              onRequestClose={toggleModal}
              contentLabel="New game confirmation modal"
            >
              <p>Are you sure?</p>
              <p>All progress will be lost...</p>
              <button
                data-testid="confirmResetProgress"
                className="button bg-green"
                onClick={resetProgress}
              >
                Yes
              </button>
              <button className="button bg-red" onClick={toggleModal}>
                No
              </button>
            </Modal>
            <ModalWindow
              content={
                <>
                  <h3>{handleDisplayTitle()}</h3>
                  <ul>{handleDisplayContent()}</ul>
                  <button
                    className="button small"
                    onClick={() => {
                      dispatch(newGamePlusStarted());
                      setNarrationModalTrigger(!narrationModalTrigger);
                    }}
                  >
                    close
                  </button>
                </>
              }
              contentLabel="Narration modal"
              isOpen={narrationModalTrigger}
            />
          </article>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Welcome;
