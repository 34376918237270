import React from "react";
import LinkButton from "../universal/linkButton";
import DialogueMenu from "../universal/dialogueMenu";
import dialogues from "../../utils/dialogues";
import useCheckPlaceAccess from "../hooks/useCheckPlaceAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import { itemPurchased } from "../../store/auth";
import HeaderWithLogo from "../universal/headerWithLogo";
import Header from "../universal/header";
import shop_png from "../../images/shop.png";
import shop_webp from "../../images/shop.webp";
import "../planets/planet.css";
import "./shop.css";

const Shop = () => {
  useCheckPlaceAccess("shop");
  useChangeGameStatus();

  const dispatch = useDispatch();

  const { currentPlanet, inventory } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          inventory: [],
        }
  );

  const placeDescription =
    "Due to the significant increase in the number of thefts in thegalaxy in recent times, stores temporarily suspended the possibility of buying goods - several traders had legal problemsafter it turned out that the goods that were sold to them were stolen.";

  const renderBuyButton = (element) => {
    return element <= inventory.credits ? `${element}[!]` : "Not enough [!]";
  };

  const shopInventory = {
    word: 450,
    stardust: 5000,
    steel: 3500,
    aluminum: 4000,
  };

  const renderItemsForSale = (list) => {
    const items = [];
    for (let [item, price] of Object.entries(list)) {
      items.push([item, price]);
    }
    return items.map((element) => (
      <li key={element[0]}>
        {element[0]}{" "}
        <button
          className="button small cubical margin-0-3rem"
          onClick={() => {
            dispatch(
              itemPurchased({
                item: element[0],
                amount: 1,
                price: element[1],
                multiplier: 1,
              })
            );
          }}
        >
          {renderBuyButton(element[1])}
        </button>
      </li>
    ));
  };

  const renderUserInventory = () => {
    const items = [];
    for (let [item, amount] of Object.entries(inventory)) {
      if (
        item !== "favs" &&
        item !== "moonshine" &&
        item !== "mercenaries" &&
        item !== "expeditions" &&
        item !== "moonshiner" &&
        item !== "traveler" &&
        item !== "rhetoric" &&
        amount
      ) {
        items.push([item, amount]);
      }
    }
    if (items.length > 0) {
      return items.map((element) => (
        <li key={element[0]}>
          {element[0]} - {element[1]}{" "}
        </li>
      ));
    } else {
      return <p>The inventory is empty</p>;
    }
  };

  const renderBlockedUserInventory = () => {
    const items = [];
    for (let [item, amount] of Object.entries(inventory)) {
      if (
        item !== "favs" &&
        item !== "credits" &&
        item !== "moonshine" &&
        item !== "moonshiner" &&
        item !== "traveler" &&
        item !== "rhetoric" &&
        item !== "mercenaries" &&
        item !== "crystal" &&
        item !== "expeditions" &&
        amount
      ) {
        items.push([item, amount]);
      }
    }
    if (items.length > 0) {
      return items.map((element) => (
        <li key={element[0]}>
          {element[0]} -{" "}
          <button className="button small cubical margin-0-3rem">
            Suspended
          </button>
        </li>
      ));
    } else {
      return <p>You have nothing to sell</p>;
    }
  };

  return (
    <main
      className="shop-wrapper flex-auto main-wrapper"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <section id="shop" className="place">
        <section className="header-container">
          <HeaderWithLogo
            headerSize={"h2"}
            header={"shop"}
            text={placeDescription}
            webp={shop_webp}
            png={shop_png}
            size={"150em"}
            alt={
              "A big black neon sign with red lettering that says open. A shop logo."
            }
          />
          <section>
            <Header headerSize={"h3"} header={"trade"} underline />
            <article className="section-split margin-bottom-2rem">
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"your inventory"} />
                <div>
                  <ul>{renderUserInventory()}</ul>
                </div>
              </article>
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"buy"} />
                <div>
                  <ul>{renderItemsForSale(shopInventory)}</ul>
                </div>
              </article>
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"sell"} />
                <div>
                  <ul>{renderBlockedUserInventory()}</ul>
                </div>
              </article>
            </article>
          </section>
          <section>
            <Header headerSize={"h3"} header={"talk"} underline />
            <article>
              <Header headerSize={"h4"} header={"shopkeeper"} />
              {dialogues[currentPlanet].length !== 0 && (
                <DialogueMenu place={"shop"} />
              )}
            </article>
          </section>
          <LinkButton destination={currentPlanet} title={"walk away"} />
        </section>
      </section>
    </main>
  );
};

export default Shop;
