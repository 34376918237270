import { createSlice } from "@reduxjs/toolkit";
import { getStoreData } from "./utils";

const INITIAL_STATE = {
  navbarBg: "transparent",
};

const slice = createSlice({
  name: "game",
  initialState: getStoreData("environment.game", INITIAL_STATE),

  reducers: {
    navbarBgChanged: (state, action) => {
      if (action.payload >= 80) {
        state.navbarBg = "dark";
      } else {
        state.navbarBg = "transparent";
      }
    },
  },
});

export const { navbarBgChanged } = slice.actions;

export default slice.reducer;
