import React, { useState, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import VisibilityIcon from "../universal/visibilityIcon";
import { RiLock2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../store/utils";
import { changePassword } from "../../store/auth";
import PuffLoader from "react-spinners/PuffLoader";
import debounce from "../../utils/debounce";
import { navbarBgChanged } from "../../store/game";
import "./passwordReset.css";

const PasswordReset = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const dispatch = useDispatch();

  const status = useSelector((state) => state.user.auth.status);

  const {
    register: registerChangePassword,
    handleSubmit: handleRegisterChangePassword,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      password: null,
      confirmpassword: null,
    },
  });

  const url = window.location;
  const token = url.hash.split("#access_token=")[1];

  const handleResetPassword = useCallback(
    (data) => {
      if (data.password === data.confirmpassword) {
        dispatch(changePassword({ token, formData: data }));
      } else notify("Password fields must have the same value");
    },
    [dispatch, token]
  );

  const debouncedHandleResetPassword = useMemo(
    () => debounce((data) => handleResetPassword(data), 400),
    [handleResetPassword]
  );

  return (
    <section
      className="background auth-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <div id="auth-password-reset">
        <section className="auth-container main-background border border-radius padding margin-block-auth-container">
          <div className="padding border">
            <h3>Welcome</h3>
            <article className="activities-container">
              <div className="auth-form-container">
                <PuffLoader
                  loading={status === "loading"}
                  size={150}
                  color={"#ffffff"}
                />
                {status !== "loading" && (
                  <>
                    <RiLock2Line size={"2rem"} />
                    <span className="margin-05-0">{"Reset password"}</span>
                    <form
                      className="auth-form"
                      onSubmit={handleRegisterChangePassword(
                        debouncedHandleResetPassword
                      )}
                    >
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder={"New password *"}
                        {...registerChangePassword("password", {
                          required: true,
                          minLength: 8,
                          maxLength: 25,
                        })}
                      />
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder={"Confirm new password *"}
                        {...registerChangePassword("confirmpassword", {
                          required: true,
                          minLength: 8,
                          maxLength: 25,
                        })}
                      />
                      <VisibilityIcon
                        condition={isPasswordVisible}
                        toggler={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                      <button
                        className="button"
                        margin={"1rem 0"}
                        type="submit"
                      >
                        Reset password
                      </button>
                    </form>
                  </>
                )}
              </div>
            </article>
          </div>
        </section>
      </div>
    </section>
  );
};

export default PasswordReset;
