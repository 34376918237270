import React from "react";
import PropTypes from "prop-types";
import pad_png from "../../images/launch.png";
import pad_webp from "../../images/launch.webp";
import TravelButton from "./travelButton";
import TravelImage from "./travelImage";
import "../planets/planet.css";

const PlaceLaunchPad = ({
  title,
  nextLabel,
  reqRocketLvlNext,
  reqUserLvlNext,
  reqUserLvlPrev,
  reqRocketLvlPrev,
  nextPlanet,
  prevLabel,
  prevPlanet,
}) => {
  return (
    <article className="padding-places border flex-centered carousel-card">
      <h4>{title}</h4>
      <TravelImage
        destinationPlanet={nextPlanet}
        requiredPlayerLvl={reqUserLvlNext}
        requiredRocketLvl={reqRocketLvlNext}
        image_webp={pad_webp}
        image_png={pad_png}
        size={"100em"}
      />
      <TravelButton
        requiredPlayerLvl={reqUserLvlPrev}
        requiredRocketLvl={reqRocketLvlPrev}
        destinationPlanet={prevPlanet}
        label={prevLabel}
      />
      <TravelButton
        requiredPlayerLvl={reqUserLvlNext}
        requiredRocketLvl={reqRocketLvlNext}
        destinationPlanet={nextPlanet}
        label={nextLabel}
      />
    </article>
  );
};

export default PlaceLaunchPad;

PlaceLaunchPad.propTypes = {
  title: PropTypes.string,
  nextLabel: PropTypes.string,
  reqRocketLvlNext: PropTypes.number,
  reqUserLvlNext: PropTypes.number,
  reqUserLvlPrev: PropTypes.number,
  reqRocketLvlPrev: PropTypes.number,
  nextPlanet: PropTypes.string,
  prevLabel: PropTypes.string,
  prevPlanet: PropTypes.string,
};
