import getRandomWords from "../utils/wordsList";
import api from "../utils/api";

export const getHiredAndSelectedMercenaries = (mercenaries) => {
  const { hired, selected, dead, sent } = mercenaries;

  return hired.filter(
    (merc) =>
      selected.includes(merc) && !dead.includes(merc) && !sent.includes(merc)
  );
};

export const getDeadMercenaries = (mercenaries, npcList) => {
  const { dead } = mercenaries;

  return npcList.filter((npc) => dead.includes(npc._id));
};

export const getHiredMercenaries = (mercenaries, npcList) => {
  const { dead, sent, hired } = mercenaries;
  return npcList.filter(
    (npc) =>
      hired.includes(npc._id) &&
      !dead.includes(npc._id) &&
      !sent.includes(npc._id)
  );
};

export const getMercenariesForHire = (mercenaries, npcList, currentPlanet) => {
  const { hired, dead } = mercenaries;
  return npcList.filter(
    (npc) =>
      !hired.includes(npc._id) &&
      npc.planet === currentPlanet &&
      !dead.includes(npc._id)
  );
};

export const getHiredAndSentMercenaries = (mercenaries) => {
  const { hired, dead, sent } = mercenaries;

  return hired.filter(
    (merc) => !dead.includes(merc._id) && sent.includes(merc._id)
  );
};

export const getSentAndAliveMercenaries = (mercenaries) => {
  const { dead, sent } = mercenaries;

  return sent.filter((merc) => !dead.includes(merc._id));
};

export const checkIfNarrationAvailable = (narration, currentPlanet) => {
  if (!narration) return;
  const { completed, unlocked } = narration;

  return !completed.includes(currentPlanet) && unlocked.includes(currentPlanet);
};

export const getRandomWordsWithDefinitions = async (number) => {
  const randomWords = getRandomWords(number);
  const wordsWithDefs = [];
  for (let word of randomWords) {
    wordsWithDefs.push({ word, definition: await api.getWordData(word) });
  }
  return wordsWithDefs;
};

export const shuffle = (arr) => {
  let shuffled = [...arr];
  for (let i = shuffled.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * i);
    let k = shuffled[i];
    shuffled[i] = shuffled[j];
    shuffled[j] = k;
  }
  return shuffled;
};
