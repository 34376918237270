import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import debounce from "../../utils/debounce";
import "./linkButton.css";

const LinkButton = ({
  size,
  empty,
  emptyCallback,
  title,
  linkCallback,
  destination,
}) => {
  const renderSize = () => {
    return size === "large" ? "button large" : "button small";
  };

  const renderButton = () => {
    if (empty) {
      return (
        <button
          onClick={debounce(emptyCallback, 1000)}
          className={renderSize()}
        >
          {title}
        </button>
      );
    } else {
      return (
        <Link
          onClick={linkCallback}
          className={"link-button"}
          to={`/${destination}`}
          style={{ textDecoration: "none" }}
        >
          <button className={renderSize()}>{title}</button>
        </Link>
      );
    }
  };

  return <>{renderButton()}</>;
};

export default LinkButton;

LinkButton.propTypes = {
  size: PropTypes.string,
  empty: PropTypes.bool,
  emptyCallback: PropTypes.func,
  title: PropTypes.string,
  linkCallback: PropTypes.func,
  destination: PropTypes.string,
};
