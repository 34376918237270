import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { RiLock2Line } from "react-icons/ri";
import { useForm } from "react-hook-form";
import debounce from "../../utils/debounce";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/auth";

const PasswordResetReqForm = ({ setIsPasswordReset }) => {
  const dispatch = useDispatch();

  const {
    register: registerResetPassword,
    handleSubmit: handleRegisterResetPassword,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      email: null,
    },
  });

  const handleResetPassword = useCallback(
    (data) => {
      dispatch(resetPassword(data));
    },
    [dispatch]
  );

  const debouncedHandleResetPassword = useMemo(
    () => debounce((data) => handleResetPassword(data), 400),
    [handleResetPassword]
  );

  return (
    <section className="auth-form-container">
      <RiLock2Line size={"2rem"} />
      <span className="margin-05-0">Reset password</span>
      <p className="auth-description">
        If you have lost your password, we'll email you a link to reset it.{" "}
        <br /> <br />
        Didn't get an email? Don't forget to check the SPAM folder.
      </p>
      <form
        className="auth-form"
        onSubmit={handleRegisterResetPassword(debouncedHandleResetPassword)}
      >
        <input
          type="text"
          placeholder={"Email address *"}
          {...registerResetPassword("email", {
            required: true,
            minLength: 5,
            maxLength: 40,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
        <button
          className="button"
          margin={"0 0 1rem 0"}
          type="submit"
          red={true}
          color={"white"}
        >
          Reset password
        </button>
        <span
          className="back-button"
          onClick={() => {
            setIsPasswordReset(false);
          }}
        >
          Go back
        </span>
      </form>
    </section>
  );
};

export default PasswordResetReqForm;

PasswordResetReqForm.propTypes = {
  setIsPasswordReset: PropTypes.object,
};
