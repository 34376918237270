import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { availablePlanetSet } from "../../store/auth";
import LinkFastTravel from "./linkFastTravel";
import "./miniMap.css";

const MiniMap = ({ planets }) => {
  const {
    planets: { available, discovered },
    currentPlanet,
  } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          planets: { available: [], discovered: [] },
        }
  );

  const dispatch = useDispatch();

  const ifCurrent = (planet) => {
    return available.includes(planet) ? "current-planet" : "";
  };

  const renderMap = (planets) => {
    return planets.map((planet) => (
      <li key={planet} className={ifCurrent(planet)}>
        <LinkFastTravel
          title={planet}
          available={discovered.includes(planet) && planet !== currentPlanet}
          linkCallback={() => dispatch(availablePlanetSet(planet))}
        />
      </li>
    ));
  };

  return (
    <section>
      <ul className="sidebar">
        <li>Travel:</li>
        {renderMap(planets)}
      </ul>
    </section>
  );
};

export default MiniMap;

MiniMap.propTypes = {
  webp: PropTypes.array,
};
