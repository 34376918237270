import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { modalStyle } from "../../utils/renders";
import "./modal.css";

Modal.setAppElement(document.getElementById("root"));

const ModalWindow = ({
  content,
  contentLabel,
  onRequestClose,
  isOpen,
  dataTestid,
}) => {
  return (
    <Modal
      style={modalStyle}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
    >
      {onRequestClose && (
        <i
          data-testid={dataTestid}
          onClick={onRequestClose}
          className="far fa-times-circle modal-button"
        ></i>
      )}
      <section className="padding-1rem">{content}</section>
    </Modal>
  );
};

export default ModalWindow;

ModalWindow.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  contentLabel: PropTypes.string,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
  dataTestid: PropTypes.string,
};
