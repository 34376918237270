import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { movementPointsSubtracted } from "../../store/auth";

const PlaceUfo = ({ title, img_png, img_webp }) => {
  const dispatch = useDispatch();
  const { currentPlanet, ufoDefeated } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          ufoDefeated: [],
        }
  );

  const ifUfoDefeated = ufoDefeated.includes(currentPlanet);

  const ufoLink = () => {
    return ifUfoDefeated ? `/${currentPlanet}` : "/ufo";
  };

  const ufoDescription = () => {
    return ifUfoDefeated
      ? "UFO is defeated or has flown away."
      : "You can attack and win or lose everything.";
  };

  return (
    <article className={`padding-places border carousel-card`}>
      <h4>{title}</h4>
      <Link
        onClick={
          ifUfoDefeated ? null : () => dispatch(movementPointsSubtracted(5))
        }
        to={ufoLink(currentPlanet)}
      >
        <picture className="image fit padding-inline-1">
          <source srcSet={img_webp} type="image/webp" />
          <source srcSet={img_png} type="image/png" />
          <img
            src={img_png}
            type="image/png"
            width="100em"
            height="auto"
            alt={
              "Several black and menacing looking UFO ships on a yellow background. A UFO logo."
            }
          />
        </picture>
      </Link>
      <p className="align-center">{ufoDescription(currentPlanet)}</p>
    </article>
  );
};

export default PlaceUfo;

PlaceUfo.propTypes = {
  title: PropTypes.string,
  img_png: PropTypes.string,
  img_webp: PropTypes.string,
};
