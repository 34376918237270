import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const useCheckPlanetAccess = (planet) => {
  const history = useHistory();
  const { currentUser } = useSelector((state) => state.user.auth);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("rocket-english-profile"));
    if (!currentUser || !user) {
      history.push("/auth");
      return;
    }
    const redirect = currentUser.progress.planets.available.includes(planet);
    if (!redirect) history.push("/space");
  }, [currentUser, history, planet]);
};

export default useCheckPlanetAccess;
