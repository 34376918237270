/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import renders from "../../utils/renders";
import Article from "../universal/article";
import ArticleUnderlined from "../universal/articleWithUnderlinedHeader";
import MiniMap from "../universal/miniMap";
import { planets } from "../../utils/renders";
import { narration as plotNarration } from "../../utils/dialogues";
import { useDispatch, useSelector } from "react-redux";
import AliceCarousel from "react-alice-carousel";
import { checkIfNarrationAvailable } from "../../store/helpers";
import { narrationCompleted } from "../../store/auth";
import ModalWindow from "../universal/modal";
import "react-alice-carousel/lib/alice-carousel.css";
import "./planet.css";
import "../../App.css";

const Planet = ({
  planet,
  planetDescription,
  infrastructure,
  climate,
  inhabitants,
  places,
}) => {
  const { currentUser } = useSelector((state) => state.user.auth);
  const currentPlanet = currentUser ? currentUser.progress.currentPlanet : null;
  const narration = currentUser ? currentUser.progress.narration : null;
  const [narrationModalTrigger, setNarrationModalTrigger] = useState(false);

  const toggleNarrationModal = () => {
    setNarrationModalTrigger(!narrationModalTrigger);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (checkIfNarrationAvailable(narration, currentPlanet)) {
      toggleNarrationModal();
    }
  }, [currentPlanet, narration]);

  const handleDisplayContent = () => {
    const ifAvailable = checkIfNarrationAvailable(narration, currentPlanet);
    if (ifAvailable) {
      return plotNarration[currentPlanet][0].content.map((contentType) => (
        <li key={contentType.id}>{contentType.text}</li>
      ));
    }
  };

  const handleDisplayTitle = () => {
    const ifAvailable = checkIfNarrationAvailable(narration, currentPlanet);
    if (ifAvailable) {
      return plotNarration[currentPlanet][0].title;
    }
  };

  return (
    <React.Fragment>
      <div id="planet" className="main-background" data-testid="planet">
        <div className="planet-header-container">
          <section className="planet-split">
            <ArticleUnderlined
              headerSize={"h2"}
              header={planet}
              text={planetDescription}
            />
            <section></section>
          </section>
          <div className="places-header">
            <h3>Places to visit</h3>
            <hr className="underline-places" />
          </div>
          <section className="planet-activities-container">
            <AliceCarousel
              disableDotsControls
              controlsStrategy={"responsive"}
              responsive={renders.carousel}
              keyboardNavigation
              infinite
              items={places}
            />
          </section>
          <section className="planet-info-container planet-split">
            <Article
              headerSize={"h4"}
              header={"Climate"}
              text={climate}
              styles={"planet-stats-first"}
            />
            <Article
              headerSize={"h4"}
              header={"Infrastructure"}
              text={infrastructure}
              styles={"planet-stats-middle"}
            />
            <Article
              headerSize={"h4"}
              header={"Inhabitants"}
              text={inhabitants}
              styles={"planet-stats-last"}
            />
          </section>
          <MiniMap planets={planets} />
        </div>
      </div>
      <ModalWindow
        isOpen={narrationModalTrigger}
        contentLabel="Narration modal"
        content={
          <>
            <h3>{handleDisplayTitle()}</h3>
            <ul>{handleDisplayContent()}</ul>
            <button
              data-testid="closeNarrationButton"
              className="button small"
              onClick={() => {
                dispatch(narrationCompleted(currentPlanet));
                toggleNarrationModal();
              }}
            >
              close
            </button>
          </>
        }
      />
    </React.Fragment>
  );
};

export default Planet;

Planet.propTypes = {
  planet: PropTypes.string,
  planetDescription: PropTypes.string,
  infrastructure: PropTypes.string,
  climate: PropTypes.string,
  inhabitants: PropTypes.string,
  places: PropTypes.array,
};
