import React, { useState } from "react";
import DialogueMenu from "../universal/dialogueMenu";
import useCheckPlaceAccess from "../hooks/useCheckPlaceAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import dialogues from "../../utils/dialogues";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import ModalWindow from "../universal/modal";
import {
  expAdded,
  creditsAdded,
  itemPurchased,
  itemsExchanged,
} from "../../store/auth";
import { navbarBgChanged } from "../../store/game";
import HeaderWithLogo from "../universal/headerWithLogo";
import LinkButton from "../universal/linkButton";
import { getRandomWordsWithDefinitions, shuffle } from "../../store/helpers";
import alchemy_webp from "../../images/alchemy.webp";
import alchemy_png from "../../images/alchemy.png";
import Header from "../universal/header";
import "../planets/planet.css";
import "./alchemist.css";
import "../../App.css";

const Alchemist = () => {
  useCheckPlaceAccess("alchemist");
  useChangeGameStatus();

  const dispatch = useDispatch();
  const [riddle, setRiddle] = useState([]);
  const [riddleShuffled, setRiddleShuffled] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentPlanet, inventory } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          inventory: [],
        }
  );

  const [modalTrigger, setModalTrigger] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const toggleModal = () => {
    if (modalTrigger) {
      setRiddle([]);
      setRiddleShuffled([]);
      setModalContent("");
    }
    setModalTrigger(!modalTrigger);
  };

  const renderBuyButton = (element) => {
    return element <= inventory.credits
      ? `${element}${element === 1 ? "moonshine" : "[!]"}`
      : "Not enough [!]";
  };

  const alchemistInventory = {
    traveler: 8500,
    rhetoric: 8000,
    moonshiner: 1,
  };

  const renderItemsForSale = (list) => {
    const items = [];
    for (let [item, price] of Object.entries(list)) {
      items.push([item, price]);
    }
    return items.map((element) => (
      <li key={element[0]}>
        {element[0]}{" "}
        <button
          className="button small cubical margin-0-3rem"
          onClick={() => {
            dispatch(
              element[0] !== "moonshiner"
                ? itemPurchased({
                    item: element[0],
                    amount: 1,
                    price: element[1],
                    multiplier: 1,
                  })
                : itemsExchanged({
                    give: { moonshine: 1 },
                    get: { moonshiner: 1 },
                  })
            );
          }}
        >
          {renderBuyButton(element[1])}
        </button>
      </li>
    ));
  };

  const placeDescription =
    "Alchemists have acquired the knowledge and skills thanks to which they can prepare various potions. There are rumors that some of these potions can bring someone back to life. It is rumored that a mercenary was seen in a bar in Therion after he died fighting on Xillon.";

  const handleGetRiddle = async () => {
    reset({});
    try {
      setLoading(true);
      let riddles = await getRandomWordsWithDefinitions(5);
      setRiddle(riddles);
      setRiddleShuffled(shuffle([...riddles]));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const {
    register: registerAnswer,
    handleSubmit: handleSubmitAnswer,
    reset,
    // formState: { errors },
  } = useForm();

  const renderSpinner = (size) => {
    return (
      <PuffLoader
        loading={loading}
        size={size}
        color={"white"}
        speedMultiplier={0.8}
      />
    );
  };

  const handleAnswer = (data) => {
    const answers = [];
    let correct = 0;
    let creditsWon = 0;
    let expWon = 0;

    for (let [word, answer] of Object.entries(data)) {
      if (word === answer) {
        answers.push(
          <li key={word}>
             <span className="win">{answer}</span>
          </li>
        );

        creditsWon += 150;
        expWon += 100;
        correct += 1;
      } else {
        answers.push(
          <li key={word}>
             <span className="lose">{answer}</span>
          </li>
        );
      }
    }

    if (expWon) {
      dispatch(expAdded(expWon));
      answers.push(
        <li key={Math.random()}>
          <span className="win">{`+${expWon} exp`}</span>
        </li>
      );
    }

    if (creditsWon) {
      dispatch(creditsAdded(creditsWon));
      answers.push(
        <li key={Math.random()}>
          <span className="win">{`+${creditsWon} [!]`}</span>
        </li>
      );
    }

    if (correct >= 5) {
      dispatch(expAdded(500));
      answers.push(
        <li key={Math.random()}>
          <span className="win">+500 extra exp</span>
        </li>
      );
    }
    setModalContent(<ul>{answers.map((answer) => answer)}</ul>);
    toggleModal();
  };

  return (
    <main
      className="alchemist-wrapper flex-auto main-wrapper"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <section id="alchemist" className="place">
        <section className="header-container">
          <HeaderWithLogo
            headerSize={"h2"}
            header={"alchemist"}
            text={placeDescription}
            webp={alchemy_webp}
            png={alchemy_png}
            size={"150em"}
            alt={"Transparent bottle with a snake's eye and tail."}
          />
          <section>
            <Header headerSize={"h3"} header={"alchemy"} underline />
            <article className="margin-bottom-2rem">
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"potions"} />
                <p className="centered-2rem margin-top-1-5rem">
                  <span className="win">Traveler</span> increases your maximum
                  movement points.
                  <br />
                  <br />
                  <span className="win">Rhetoric</span> makes sellers offer
                  better prices.
                  <br />
                  <br />
                  <span className="win">Moonshiner</span> moonshiner allows you
                  to read the minds of certain beings.
                </p>
                <ul>{renderItemsForSale(alchemistInventory)}</ul>
              </article>
              <article className="align-self-flex-start article-width">
                <Header headerSize={"h4"} header={"alchemist"} />
                {riddle.length === 0 && !loading && (
                  <p className="centered-2rem margin-top-1-5rem">
                    There was a small fire in the lab and my potion labels were
                    torn. Now I'm having trouble reading what's in those
                    bottles. I will give you descriptions and you can help me
                    assign the appropriate labels. All right?
                  </p>
                )}
                {loading && (
                  <div className="loader">{renderSpinner("10rem")}</div>
                )}
                {riddle.length !== 0 && !loading && (
                  <>
                    <div className="win padding-bottom-05rem labels">
                      {riddleShuffled.map(({ word }, index) => (
                        <span className="padding-1rem" key={index}>
                          {word}
                        </span>
                      ))}
                    </div>
                    <form
                      className="centered"
                      onSubmit={handleSubmitAnswer(handleAnswer)}
                    >
                      {riddle.map(({ word, definition }) => (
                        <React.Fragment key={word}>
                          <label>{definition}</label>
                          <input
                            type="text"
                            {...registerAnswer(`${word}`, {
                              required: true,
                              maxLength: 15,
                            })}
                          />
                        </React.Fragment>
                      ))}
                      <button className="button" type="submit">
                        Answer
                      </button>
                    </form>
                  </>
                )}
                {riddle.length === 0 && !loading && (
                  <button className="button" onClick={() => handleGetRiddle()}>
                    Try to help
                  </button>
                )}
              </article>
            </article>
          </section>
          <section>
            <Header headerSize={"h3"} header={"talk"} underline />
            <article>
              <Header headerSize={"h4"} header={"alchemist"} />
              {dialogues[currentPlanet].length !== 0 && (
                <DialogueMenu place={"alchemist"} />
              )}
            </article>
          </section>
          <LinkButton destination={currentPlanet} title={"walk away"} />
        </section>
        <ModalWindow
          content={modalContent}
          contentLabel={"Alchemist"}
          isOpen={modalTrigger}
          onRequestClose={toggleModal}
        />
      </section>
    </main>
  );
};

export default Alchemist;
