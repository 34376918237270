import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import shop_webp from "../../images/shop.webp";
import shop_png from "../../images/shop.png";
import rocket_webp from "../../images/rocket.webp";
import rocket_png from "../../images/rocket.png";
import casino_webp from "../../images/casino.webp";
import casino_png from "../../images/casino.png";
import bar_webp from "../../images/bar.webp";
import bar_png from "../../images/bar.png";
import ufo_webp from "../../images/ufo-logo.webp";
import ufo_png from "../../images/ufo-logo.png";
import mine_webp from "../../images/mine.webp";
import mine_png from "../../images/mine.png";
import university_webp from "../../images/university.webp";
import university_png from "../../images/university.png";
import quiz_webp from "../../images/quiz.webp";
import quiz_png from "../../images/quiz.png";
import satelite_webp from "../../images/satelite.webp";
import satelite_png from "../../images/satelite.png";
import ArticleWithImage from "../universal/articleWithImage";
import { howToPlay } from "../../utils/texts";
import { useDispatch } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import "./help.css";
import "../nav.css";

const Help = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(navbarBgChanged(0));
    };
  }, [dispatch]);

  const images = {
    Trade: { png: shop_png, webp: shop_webp },
    Gambling: { png: casino_png, webp: casino_webp },
    Mercenaries: { png: bar_png, webp: bar_webp },
    Factory: { png: rocket_png, webp: rocket_webp },
    Combat: { png: ufo_png, webp: ufo_webp },
    Mining: { png: mine_png, webp: mine_webp },
    Tests: { png: university_png, webp: university_webp },
    Quizes: { png: quiz_png, webp: quiz_webp },
    Favorites: { png: satelite_png, webp: satelite_webp },
  };

  return (
    <div
      className="help-wrapper flex-auto main-wrapper"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <main id="help" className="main-background">
        <div className="header-container">
          <section className="section-split header-container">
            <header>
              <article className="content-help">
                <h2>How to play</h2>
                <hr className="underline-help" />
                <p className="place-description-help">
                  By discovering the planets of the fictional galaxy, you will
                  get to know many interesting and strange places that will
                  allow you to increase your progress in exploring this
                  extraordinary world. In the guest mode, you can easily learn
                  the story and explore even the farthest corners of the galaxy
                  without meeting any additional criteria, and you can also
                  enjoy all the attractions that await on the planets and
                  beyond. The player mode is more of a challenge, as moving
                  around requires a certain level of character and a rocket. The
                  levels increase with the amount of experience gained that you
                  get by taking up challenges. Below is a detailed description
                  of the most important features.
                </p>
              </article>
            </header>
            <section></section>
          </section>
        </div>
        {howToPlay.map((article) => (
          <ArticleWithImage
            key={article.header}
            {...article}
            src={images[article.header].png}
            png={images[article.header].png}
            webp={images[article.header].webp}
          />
        ))}
        <button className="button small">
          <Link to={"/"} style={{ textDecoration: "none" }}>
            Go Back
          </Link>
        </button>
      </main>
    </div>
  );
};

export default Help;
