import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { availablePlanetSet } from "../store/auth";
import Timer from "./universal/timer";
import Icon from "./universal/icon";
import { BsGear } from "react-icons/bs";
import { FaPowerOff } from "react-icons/fa";
import decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { logout, saveGame, gamePauseStatusSwitched } from "../store/auth";

import "./nav.css";

const Nav = () => {
  const [clicked, setClicked] = useState(false);
  const [destroyed, setDestroyed] = useState("fas fa-rocket rocket-icon");
  let decodedToken = useRef();

  const ref = useRef();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("rocket-english-profile"));

    let token = user?.credential;
    if (token) {
      decodedToken.current = decode(token);
    }
  }, [location]);

  const { navbarBg } = useSelector((state) => state.environment.game);
  const { currentUser } = useSelector((state) => state.user.auth);
  const currentPlanet = currentUser
    ? currentUser?.progress?.currentPlanet
    : "menu";
  const gamePaused = currentUser ? currentUser?.progress?.gamePaused : false;

  const movement = currentUser?.progress?.movement;
  const dispatch = useDispatch();

  const handleClick = () => setClicked(!clicked);

  const handleOpenMenu = () => {
    dispatch(availablePlanetSet("menu"));
  };

  const handleCloseMenuForExpedition = () => {
    if (clicked) {
      setClicked(false);
    }
  };

  const destroy = () => {
    if (destroyed !== "hidden-logo" && !clicked) {
      setDestroyed("break");
      setTimeout(() => {
        setDestroyed("hidden-logo");
      }, 4700);
    }
  };

  return (
    <div id="navbar-wrapper" ref={ref}>
      <div
        id="navbar-items"
        className={
          navbarBg === "dark"
            ? "text-center bg-dark"
            : "text-center bg-transparent"
        }
      >
        <span
          onClick={() => {
            destroy();
          }}
        >
          <Icon cls={`fas fa-rocket rocket-icon ${destroyed}`} />
        </span>
        <div className="nav-logo">
          <NavLink
            className={"navbar-link"}
            onClick={
              currentUser
                ? () => {
                    handleOpenMenu();
                    dispatch(gamePauseStatusSwitched(true));
                  }
                : null
            }
            to="/"
          >
            <h1>ROCKET ENGLISH</h1>
          </NavLink>
          <ul className={clicked ? "nav-menu active" : "nav-menu"}>
            {(currentPlanet === "menu" || gamePaused) && (
              <li className="nav-links menu">
                <NavLink
                  onClick={() => setClicked(false)}
                  className="navbar-navlink"
                  to="/help"
                >
                  How to Play
                </NavLink>
              </li>
            )}
            {currentPlanet !== "menu" && !gamePaused && (
              <li className={"nav-links"}>
                <NavLink
                  onClick={() => setClicked(false)}
                  className="navbar-navlink"
                  to="/trophies"
                >
                  <i className="fas fa-trophy"></i> Trophies
                </NavLink>
              </li>
            )}
            {currentPlanet !== "menu" && !gamePaused && (
              <li
                className={
                  currentPlanet !== "menu" ? "nav-links" : "nav-hidden"
                }
              >
                <NavLink
                  onClick={() => setClicked(false)}
                  className="navbar-navlink"
                  to="/favorites"
                >
                  <i className="fas fa-star"></i> Favorites
                </NavLink>
              </li>
            )}
            {currentPlanet !== "menu" && !gamePaused && (
              <li
                className={
                  currentPlanet === "menu" ? "nav-hidden" : "nav-links"
                }
              >
                <NavLink
                  onClick={() => setClicked(false)}
                  className="navbar-navlink"
                  to="/inventory"
                >
                  <i className="fas fa-box-open"></i> Inventory
                </NavLink>
              </li>
            )}
            {currentPlanet !== "menu" && !gamePaused && (
              <>
                <li className={currentPlanet === "menu" ? "nav-hidden" : ""}>
                  <i className="fas fa-user"></i>{" "}
                  {currentUser?.progress?.characterLvl}{" "}
                  <i className="fas fa-rocket"></i>{" "}
                  {currentUser?.progress?.rocketLvl}
                </li>
                <li className={currentPlanet === "menu" ? "nav-hidden" : ""}>
                  <i className="fas fa-walking"></i>
                  {movement?.currentMovePoints}/{movement?.maxMovePoints}
                </li>
              </>
            )}
            {currentPlanet !== "menu" && !gamePaused && (
              <li>
                <Timer
                  closeMenuForExpedition={handleCloseMenuForExpedition}
                  mins={1}
                  secs={0}
                />
              </li>
            )}
            {currentUser && gamePaused && (
              <li
                className="nav-links menu save-button"
                onClick={() => {
                  dispatch(saveGame(currentUser.progress));
                  setClicked(false);
                }}
              >
                Save game
              </li>
            )}
            {currentUser && gamePaused && (
              <li className="nav-links menu logout-button">
                <NavLink
                  className="logout-button"
                  to="/"
                  onClick={() => {
                    setClicked(false);
                    dispatch(logout());
                  }}
                >
                  Logout
                </NavLink>
              </li>
            )}
            {currentPlanet !== "menu" &&
              !gamePaused &&
              ((
                decodedToken.current
                  ? decodedToken.current.iss !== "https://accounts.google.com"
                  : true
              ) ? (
                <li>
                  <NavLink
                    data-testid="preferences"
                    onClick={() => setClicked(false)}
                    className="navbar-navlink"
                    to="/preferences"
                  >
                    <BsGear color={"white"} size={"1rem"} />
                  </NavLink>
                </li>
              ) : (
                <li className="nav-links">
                  <FaPowerOff
                    size={"1rem"}
                    color={"rgb(235, 110, 105)"}
                    onClick={() => {
                      setClicked(false);
                      history.push("/");
                    }}
                  />
                </li>
              ))}
          </ul>
        </div>
        <Icon
          cls={clicked ? "menu-icon fas fa-times" : "menu-icon fas fa-bars"}
          onClickAction={handleClick}
        />
      </div>
    </div>
  );
};

export default Nav;
