import React from "react";
import PropTypes from "prop-types";

const Article = ({ headerSize, header, styles, text }) => {
  const renderHeader = () => {
    if (headerSize === "h2") {
      return <h2>{header}</h2>;
    } else if (headerSize === "h3") {
      return <h3>{header}</h3>;
    } else if (headerSize === "h4") {
      return <h4>{header}</h4>;
    } else if (headerSize === "h5") {
      return <h5>{header}</h5>;
    } else {
      return <h1>{header}</h1>;
    }
  };

  return (
    <article className={styles}>
      {renderHeader()}
      <p>{text}</p>
    </article>
  );
};

export default Article;

Article.propTypes = {
  headerSize: PropTypes.string,
  header: PropTypes.string,
  styles: PropTypes.string,
  text: PropTypes.string,
};
