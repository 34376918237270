import { BiShow, BiHide } from "react-icons/bi";
import PropTypes from "prop-types";
import "./visibilityIcon.css";

const VisibilityIcon = ({ condition, toggler }) => {
  return (
    <div className="visibility-toggler" onClick={toggler}>
      <span className="margin-right-03">
        {condition ? "Hide password" : "Show password"}
      </span>
      {condition ? <BiHide /> : <BiShow />}
    </div>
  );
};
export default VisibilityIcon;

VisibilityIcon.propTypes = {
  title: PropTypes.bool,
  toggler: PropTypes.func,
};
