import React, { useState } from "react";
import LinkButton from "../universal/linkButton";
import useCheckPlaceAccess from "../hooks/useCheckPlaceAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  allItemsLost,
  ufoEncountered,
  expAdded,
  freeItemsAdded,
} from "../../store/auth";
import { navbarBgChanged } from "../../store/game";
import HeaderWithLogo from "../universal/headerWithLogo";
import ufo_webp from "../../images/ufo-logo.webp";
import ufo_png from "../../images/ufo-logo.png";
import ModalWindow from "../universal/modal";
import Header from "../universal/header";
import "../../App.css";
import "./ufo.css";

export const Ufo = ({ history }) => {
  useCheckPlaceAccess("ufo");
  useChangeGameStatus();

  const dispatch = useDispatch();

  const { currentPlanet, ufoDefeated } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          ufoDefeated: [],
        }
  );

  const placeDescription =
    "Messing with UFOs can end up bad for you because you can lose all your belongings. But if you win, you will gain some valuable items that will be useful for your further journey. Fighting UFOs is considered a noble act by the inhabitants of the galaxy as it makes the area safer.There are even special mercenary groups that are engaged in the pursuit and destruction of alien ships.";

  const [modalTrigger, setModalTrigger] = useState(false);
  const [summary, setSummary] = useState([]);
  const toggleModal = () => {
    setModalTrigger(!modalTrigger);
  };

  const renderFightButton = () => {
    if (!ufoDefeated.includes(currentPlanet)) {
      return (
        <button className="button large" onClick={() => fight(1)}>
          Fight
        </button>
      );
    }
  };

  const fight = (base) => {
    const founds = {};
    const winRate = [1.5, 2.5, 3.5];
    const loseOrWin = ["loser", "winner"];
    const result = loseOrWin[Math.floor(Math.random() * loseOrWin.length)];
    if (result === "loser") {
      dispatch(allItemsLost());
      dispatch(ufoEncountered({ planet: currentPlanet, win: false }));
      toggleModal();
    } else {
      dispatch(ufoEncountered({ planet: currentPlanet, win: true }));
      const rate = winRate[Math.floor(Math.random() * winRate.length)];
      const credits = Math.floor(base * rate * 2000);
      const exp = Math.floor(base * rate * 500);
      const steel = Math.floor(base * rate * 10) + 1;
      const aluminum = Math.floor(base * rate * 5) + 1;
      const crystal = Math.floor(base * rate * 1) + 1;
      founds["credits"] = credits;
      founds["exp"] = exp;
      founds["steel"] = steel;
      founds["aluminum"] = aluminum;
      founds["crystal"] = crystal;
      ufoSummary(founds);

      dispatch(expAdded(exp));
      dispatch(
        freeItemsAdded({
          credits: credits,
          steel: steel,
          aluminum: aluminum,
          crystal: crystal,
        })
      );

      toggleModal();
    }
  };
  const ufoSummary = (object) => {
    const items = [];
    for (let [item, amount] of Object.entries(object)) {
      items.push([item, amount]);
    }
    setSummary(items);
  };

  const renderSummary = () => {
    if (summary.length !== 0) {
      return summary.map((element) => (
        <li className="color-win" key={element[0]}>
          +{element[1]} {element[0]}{" "}
        </li>
      ));
    } else {
      return (
        <p className="color-lose">
          You lost. The enemy took your equipment and flew away...
        </p>
      );
    }
  };

  return (
    <main
      className="ufo-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <section id="ufo-enemy" className="ufo-header-container">
        <HeaderWithLogo
          headerSize={"h2"}
          header={"ufo"}
          text={placeDescription}
          webp={ufo_webp}
          png={ufo_png}
          size={"150em"}
          alt={
            "Several black and menacing looking UFO ships on a yellow background. A UFO logo."
          }
        />
        <section>
          <Header headerSize={"h3"} header={"combat"} underline />
          <article className="margin-bottom-2rem">
            <article className="align-self-flex-start">
              <Header headerSize={"h4"} header={"attack"} />
              {renderFightButton()}
            </article>
          </article>
        </section>
        <LinkButton destination={currentPlanet} title={"Go back"} />
      </section>
      <ModalWindow
        content={<ul>{renderSummary()}</ul>}
        contentLabel={"UFO"}
        isOpen={modalTrigger}
        onRequestClose={() => {
          toggleModal();
          history.push(`/${currentPlanet}`);
        }}
      />
    </main>
  );
};
export default Ufo;
