import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import LinkButton from "../universal/linkButton";
import "./preferences.css";
import AccountEditor from "./accountEditor";
import { navbarBgChanged } from "../../store/game";

const Preferences = () => {
  const [redirect, setRedirect] = useState(false);
  const progress = useSelector(
    (state) => state.user.auth.currentUser?.progress
  );
  const currentPlanet = progress ? progress.currentPlanet : null;
  const { currentUser } = useSelector((state) => state.user.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      setRedirect(true);
    }
    return () => {
      dispatch(navbarBgChanged(0));
    };
  }, [currentUser, dispatch]);

  return (
    <main
      className="preferences-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      {redirect && <Redirect to={"/"} />}
      <div id="preferences">
        <section className="preferences-container main-background border border-radius padding margin-block-preferences-container">
          <div className="padding border">
            <h3>Preferences</h3>
            <article className="preferences-activities-container">
              <AccountEditor />
            </article>
          </div>
          <LinkButton destination={currentPlanet} title={"go back"} />
        </section>
      </div>
    </main>
  );
};

export default Preferences;
