import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { navbarBgChanged } from "../../store/game";
import useCheckPlanetAccess from "../hooks/useCheckPlanetAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import Planet from "./planet";
import PlaceBasic from "../universal/placeBasic";
import PlaceLaunchPad from "../universal/placeLaunchPad";
import PlaceUfo from "../universal/placeUfo";
import {
  quiz_png,
  quiz_webp,
  rocket_png,
  rocket_webp,
  university_png,
  university_webp,
  ufo_png,
  ufo_webp,
  temple_png,
  temple_webp,
  bar_png,
  bar_webp,
} from "./imagesIndex";

const Crystalia = () => {
  const { planets } = useSelector((state) => state.environment.planet);
  const { description, infrastructure, climate, inhabitants } = planets.find(
    (planet) => planet.name === "crystalia"
  );
  const dispatch = useDispatch();

  useChangeGameStatus("crystalia");
  useCheckPlanetAccess("crystalia");

  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <PlaceUfo
      onDragStart={handleDragStart}
      title={"Ufo"}
      img_webp={ufo_webp}
      img_png={ufo_png}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Factory"}
      link={"factory"}
      img_webp={rocket_webp}
      img_png={rocket_png}
      alt={
        "A large robot and a space rocket, as well as night and stars in the background. Factory logo."
      }
      description={"Here you can upgrade your rocket."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Temple"}
      link={"temple"}
      img_webp={temple_webp}
      img_png={temple_png}
      alt={
        "A stone statue with precious stones in the shape of the sun god Helios."
      }
      description={
        "A quiet and peaceful place where you can pray for a good fortune."
      }
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"School"}
      link={"quiz"}
      img_webp={quiz_webp}
      img_png={quiz_png}
      alt={"A thick red book. A school logo."}
      description={"You can test yourself and gain exp here."}
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"Bar"}
      link={"bar"}
      img_webp={bar_webp}
      img_png={bar_png}
      alt={"A Shiny black and pink drink neon sign. A bar logo."}
      description={
        "A place for gossip and meetings. You can also hire mercenaries here."
      }
    />,
    <PlaceBasic
      onDragStart={handleDragStart}
      title={"University"}
      link={"university"}
      img_webp={university_webp}
      img_png={university_png}
      alt={"A diploma with a blue ribbon. A university logo."}
      description={
        "The challenge here is to fill in the gaps in the text. It can also be your own text."
      }
    />,
    <PlaceLaunchPad
      onDragStart={handleDragStart}
      title={"Launch pad"}
      prevPlanet={"therion"}
      nextPlanet={"thalia"}
      prevLabel={"Back to Therion"}
      nextLabel={"Go to Thalia"}
      reqUserLvlNext={20}
      reqRocketLvlNext={3}
      reqUserLvlPrev={5}
      reqRocketLvlPrev={1}
    />,
  ];

  return (
    <div
      className="crystalia-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <Planet
        key={Math.random()}
        planet={"crystalia"}
        planetDescription={description}
        places={items}
        infrastructure={infrastructure}
        climate={climate}
        inhabitants={inhabitants}
      />
    </div>
  );
};

export default Crystalia;
