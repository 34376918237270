import React, { useRef, useEffect } from "react";
import LinkButton from "../universal/linkButton";
import { useDispatch, useSelector } from "react-redux";
import { taskAddedToQueue, mercenariesStatusChanged } from "../../store/auth";
import { navbarBgChanged } from "../../store/game";
import {
  getHiredMercenaries,
  getHiredAndSelectedMercenaries,
} from "../../store/helpers";
import NpcForHireCard from "../universal/npcForHireCard";
import AliceCarousel from "react-alice-carousel";
import renders from "../../utils/renders";
import ActionButton from "../universal/actionButton";
import Header from "../universal/header";
import "react-alice-carousel/lib/alice-carousel.css";
import "./inventory.css";
import "./favorites.css";
import "../planets/planet.css";

const Inventory = () => {
  const { currentPlanet, inventory, turnNumber, taskQueue, mercenaries } =
    useSelector((state) =>
      state.user.auth.currentUser
        ? state.user.auth.currentUser.progress
        : {
            currentPlanet: null,
            inventory: [],
            turnNumber: 0,
            taskQueue: [],
            mercenaries: { dead: [], sent: [], hired: [] },
          }
    );

  const { mercenaries: npcForHire } = useSelector(
    (state) => state.environment.npc
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(navbarBgChanged(0));
    };
  }, [dispatch]);

  const mercenariesList = useRef();

  const renderMercenaries = () => {
    const npc = getHiredMercenaries(mercenaries, npcForHire);

    if (npc.length !== 0) {
      return npc.map((npc) => <NpcForHireCard mercenary={npc} />);
    } else {
      return null;
    }
  };

  const renderInventory = () => {
    const items = [];
    for (let [item, amount] of Object.entries(inventory)) {
      if (
        item !== "favs" &&
        item !== "mercenaries" &&
        item !== "expeditions" &&
        amount
      ) {
        items.push([item, amount]);
      }
    }
    return items.map((element) => (
      <li key={element[0]}>
        {element[0]} - {element[1]}{" "}
      </li>
    ));
  };

  mercenariesList.current = renderMercenaries();

  const countExpeditionETA = (name) => {
    const ETA =
      taskQueue.find((task) => task.taskName === name).startingTurnNumber -
      turnNumber;

    return ETA > 1 ? `ETA: ${ETA} turns.` : `ETA: ${ETA} turn.`;
  };

  const handleNpcStatus = () => {
    dispatch(
      mercenariesStatusChanged({
        idArray: getHiredAndSelectedMercenaries(mercenaries),
        status: "sent",
      })
    );
  };

  return (
    <main
      className="inventory-wrapper flex-auto"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <div id="inventory-container">
        <section className="planet-container main-background border border-radius padding margin-block-planet-container margin-bottom-2rem">
          <section id="inventory" className="padding border">
            <article>
              <Header headerSize={"h3"} header={"inventory"} />
              <ul>{renderInventory()}</ul>
            </article>
          </section>
          <article id="mercenaries" className="planet-activities-container">
            <Header headerSize={"h3"} header={"mercenaries"} />
            {mercenariesList.current && (
              <AliceCarousel
                disableDotsControls
                controlsStrategy={"responsive"}
                responsive={renders.carousel}
                keyboardNavigation
                infinite
                items={mercenariesList.current}
              />
            )}
            {!mercenariesList.current && (
              <p className="place-description">
                No mercenaries available. <br />
                Visit a bar to hire someone.
              </p>
            )}
          </article>
          <section className="padding border">
            <article>
              <Header headerSize={"h3"} header={"expedition"} />
              {!mercenariesList.current && (
                <p className="place-description">
                  You need at least one mercenary to proceed.
                </p>
              )}

              {getHiredAndSelectedMercenaries(mercenaries).length !== 0 &&
                taskQueue.length === 0 && (
                  <>
                    <ActionButton
                      callbackFnc={() => {
                        dispatch(
                          taskAddedToQueue({
                            id: Date.now(),
                            taskName: "expedition",
                            difficulty: 0.5,
                            delay: 1,
                            startingTurnNumber: turnNumber + 1,
                          })
                        );
                        handleNpcStatus();
                      }}
                      cls={"button small"}
                      title={"Send (easy)"}
                    />
                    <ActionButton
                      callbackFnc={() => {
                        dispatch(
                          taskAddedToQueue({
                            id: Date.now(),
                            taskName: "expedition",
                            difficulty: 1,
                            delay: 1,
                            startingTurnNumber: turnNumber + 2,
                          })
                        );
                        handleNpcStatus();
                      }}
                      cls={"button small"}
                      title={"Send (medium)"}
                    />
                    <ActionButton
                      callbackFnc={() => {
                        dispatch(
                          taskAddedToQueue({
                            id: Date.now(),
                            taskName: "expedition",
                            difficulty: 2,
                            delay: 1,
                            startingTurnNumber: turnNumber + 3,
                          })
                        );
                        handleNpcStatus();
                      }}
                      cls={"button small"}
                      title={"Send (hard)"}
                    />
                    <ActionButton
                      callbackFnc={() => {
                        dispatch(
                          taskAddedToQueue({
                            id: Date.now(),
                            taskName: "expedition",
                            difficulty: 3,
                            delay: 1,
                            startingTurnNumber: turnNumber + 5,
                          })
                        );
                        handleNpcStatus();
                      }}
                      cls={"button small"}
                      title={"Send (impossible)"}
                    />
                  </>
                )}
              {mercenariesList.current &&
                getHiredAndSelectedMercenaries(mercenaries).length === 0 &&
                taskQueue.length === 0 && <p>No mercenaries selected.</p>}
              {taskQueue.length > 0 && (
                <p>
                  Expedition in progress... <br />
                  {countExpeditionETA("expedition")}
                </p>
              )}
            </article>
          </section>
          <LinkButton destination={currentPlanet} title={"go back"} />
        </section>
      </div>
    </main>
  );
};

export default Inventory;
