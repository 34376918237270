import React from "react";
import PropTypes from "prop-types";
import "./articleWithImage.css";

const ArticleWithImage = ({
  webp,
  png,
  description,
  header,
  alt,
  side,
  src,
}) => {
  return (
    <section className="place-split-help">
      {side === "left" ? (
        <>
          <picture className="image fit padding-1rem mobile">
            <source srcSet={webp} type="image/webp" />
            <source srcSet={png} type="image/png" />
            <img
              src={png}
              type="image/png"
              width="100em"
              height="auto"
              alt={alt}
            />
          </picture>
          <article className="content-help">
            <header className="places-header-help">
              <h3>{header}</h3>
              <hr className="underline-places-help-left" />
            </header>
            <p className="place-description-help">{description}</p>
          </article>
          <picture className="image fit padding-1rem desktop">
            <source srcSet={webp} type="image/webp" />
            <source srcSet={png} type="image/png" />
            <img
              src={png}
              type="image/png"
              width="100em"
              height="auto"
              alt={alt}
            />
          </picture>
        </>
      ) : (
        <>
          <picture className="image fit padding-1rem">
            <source srcSet={webp} type="image/webp" />
            <source srcSet={png} type="image/png" />
            <img
              src={src}
              type="image/png"
              width="100em"
              height="auto"
              alt={alt}
            />
          </picture>
          <article className="content-help">
            <header className="places-header-help">
              <hr className="underline-places-help-right" />
              <h3>{header}</h3>
            </header>
            <p className="place-description-help">{description}</p>
          </article>
        </>
      )}
    </section>
  );
};

export default ArticleWithImage;

ArticleWithImage.propTypes = {
  webp: PropTypes.string,
  png: PropTypes.string,
  description: PropTypes.string,
  header: PropTypes.string,
  alt: PropTypes.string,
  side: PropTypes.string,
  src: PropTypes.string,
};
