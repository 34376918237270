import React from "react";
import PropTypes from "prop-types";
import "./logo.css";

const Logo = ({ webp, png, alt, size }) => {
  return (
    <picture className={"logo"}>
      <source srcSet={webp} type="image/webp" />
      <source srcSet={png} type="image/png" />
      <img src={png} type="image/png" width={size} height="auto" alt={alt} />
    </picture>
  );
};

export default Logo;

Logo.propTypes = {
  webp: PropTypes.string,
  png: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string,
};
