import React, { useState } from "react";
import DialogueMenu from "../universal/dialogueMenu";
import useCheckPlaceAccess from "../hooks/useCheckPlaceAccess";
import useChangeGameStatus from "../hooks/useChangeGameStatus";
import dialogues from "../../utils/dialogues";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PuffLoader } from "react-spinners";
import ModalWindow from "../universal/modal";
import { navbarBgChanged } from "../../store/game";
import {
  creditsAdded,
  expAdded,
  freeItemAdded,
  itemPurchased,
} from "../../store/auth";
import { getRandomWordsWithDefinitions, shuffle } from "../../store/helpers";
import HeaderWithLogo from "../universal/headerWithLogo";
import LinkButton from "../universal/linkButton";
import smuggler_webp from "../../images/smuggler.webp";
import smuggler_png from "../../images/smuggler.png";
import Header from "../universal/header";
import "../planets/planet.css";
import "./smuggler.css";

const Smuggler = () => {
  useCheckPlaceAccess("smuggler");
  useChangeGameStatus();

  const dispatch = useDispatch();
  const [riddle, setRiddle] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentPlanet, inventory } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          currentPlanet: null,
          inventory: [],
        }
  );

  const [modalTrigger, setModalTrigger] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const toggleModal = () => {
    if (modalTrigger) {
      setRiddle([]);
      setModalContent("");
    }
    setModalTrigger(!modalTrigger);
  };

  const placeDescription =
    "Smugglers are cunning guys, they cannot be trusted. They secretly trade forbidden moonshine in the galaxy, for which you can get rare items from the right people.";

  const handleGetRiddle = async () => {
    reset({});
    try {
      setLoading(true);
      let riddles = await getRandomWordsWithDefinitions(5);
      riddles = riddles.map((riddle) => {
        return { ...riddle, shuffled: shuffle([...riddle.word]) };
      });
      setRiddle(riddles);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const {
    register: registerAnswer,
    handleSubmit: handleSubmitAnswer,
    reset,
    // formState: { errors },
  } = useForm();

  const handleAnswer = (data) => {
    const answers = [];
    let correct = 0;
    let creditsWon = 0;
    let expWon = 0;

    for (let [word, answer] of Object.entries(data)) {
      if (word === answer) {
        answers.push(
          <li key={word}>
            {word} - <span className="win">{answer}</span>
          </li>
        );

        creditsWon += 150;
        expWon += 100;
        correct += 1;
      } else {
        answers.push(
          <li key={word}>
            {word} - <span className="lose">{answer}</span>
          </li>
        );
      }
    }

    if (expWon) {
      dispatch(expAdded(expWon));
      answers.push(
        <li key={Math.random()}>
          <span className="win">{`+${expWon} exp`}</span>
        </li>
      );
    }

    if (creditsWon) {
      dispatch(creditsAdded(creditsWon));
      answers.push(
        <li key={Math.random()}>
          <span className="win">{`+${creditsWon} [!]`}</span>
        </li>
      );
    }

    if (correct >= 5) {
      dispatch(freeItemAdded({ item: "moonshine", amount: 1 }));
      answers.push(
        <li key={Math.random()}>
          <span className="win">+1 moonshine</span>
        </li>
      );
    }
    setModalContent(<ul>{answers.map((answer) => answer)}</ul>);
    toggleModal();
  };

  const renderBuyButton = (element) => {
    return element <= inventory.credits ? `${element}[!]` : "Not enough [!]";
  };

  const renderSpinner = (size) => {
    return (
      <PuffLoader
        loading={loading}
        size={size}
        color={"white"}
        speedMultiplier={0.8}
      />
    );
  };

  const smugglerItems = () => {
    const items = [];
    for (let [item, price] of Object.entries({ moonshine: 8500 })) {
      items.push([item, price]);
    }
    return items.map((element) => (
      <li className="inline-grid" key={element[0]}>
        {element[0]}{" "}
        <button
          className="button small cubical margin-0-3rem"
          onClick={() => {
            dispatch(
              itemPurchased({
                item: element[0],
                amount: 1,
                price: element[1],
                multiplier: 1,
              })
            );
          }}
        >
          {renderBuyButton(element[1])}
        </button>
      </li>
    ));
  };
  return (
    <main
      className="smuggler-wrapper flex-auto main-wrapper"
      onScroll={(e) => dispatch(navbarBgChanged(e.currentTarget.scrollTop))}
    >
      <section id="smuggler" className="place">
        <section className="smuggler-header-container">
          <HeaderWithLogo
            headerSize={"h2"}
            header={"smuggler"}
            text={placeDescription}
            webp={smuggler_webp}
            png={smuggler_png}
            size={"150em"}
            alt={
              "A scary looking bottle with a skull on the label, the symbol of the smuggler."
            }
          />
          <section>
            <Header headerSize={"h3"} header={"help"} underline />
            <article className="section-split margin-bottom-2rem">
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"moonshine dealer"} />
                <ul>{smugglerItems()}</ul>
              </article>
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"drunk smuggler"} />
                {riddle.length === 0 && !loading && (
                  <p className="centered-2rem margin-top-1-5rem">
                    Since drinking this moonshine I can't read anymore, all the
                    letters have changed. You can help? I'll give you something
                    for that.
                  </p>
                )}
                {loading && (
                  <div className="loader">{renderSpinner("10rem")}</div>
                )}
                {riddle.length !== 0 && !loading && (
                  <form
                    className="smuggler-form"
                    onSubmit={handleSubmitAnswer(handleAnswer)}
                  >
                    {riddle.map(({ word, shuffled }) => (
                      <React.Fragment key={word}>
                        <label>{shuffled}</label>
                        <input
                          type="text"
                          {...registerAnswer(`${word}`, {
                            required: true,
                            maxLength: 15,
                          })}
                        />
                      </React.Fragment>
                    ))}
                    <button className="button" type="submit">
                      Answer
                    </button>
                  </form>
                )}
                {riddle.length === 0 && !loading && (
                  <button className="button" onClick={() => handleGetRiddle()}>
                    Help the smuggler
                  </button>
                )}
              </article>
              <article className="align-self-flex-start">
                <Header headerSize={"h4"} header={"storekeeper"} />
                <p className="centered-2rem">
                  Moonshine is forbidden in the galaxy, so it's better not to
                  brag about having it. However, there are places where you can
                  get a lot for this good.
                </p>
              </article>
            </article>
          </section>
          <section>
            <Header headerSize={"h3"} header={"talk"} underline />
            <article>
              <Header headerSize={"h4"} header={"smuggler"} />
              {dialogues[currentPlanet].length !== 0 && (
                <DialogueMenu place={"smuggler"} />
              )}
            </article>
          </section>
          <LinkButton destination={currentPlanet} title={"walk away"} />
        </section>
        <ModalWindow
          content={modalContent}
          contentLabel={"Smuggler"}
          isOpen={modalTrigger}
          onRequestClose={toggleModal}
        />
      </section>
    </main>
  );
};

export default Smuggler;
