const ufo =
  "UFO - It is unknown where the unknown spacecraft came from in the galaxy and why they are so aggressive and hostile. No attempt to land an alien ship on any of the planets in the galaxy has been reported so far. However, there are cases of attacks and kidnappings in outer space - hence the recommendation not to venture into distant corners of the galaxy without escort. So far, none of the hijacked ships has returned. Witnesses describe at least a dozen different types of spacecraft, most of which regularly orbit the planets. Not all are equally aggressive, as reportedly the type of hostile object is related to the type of ship under attack - merchant and cargo ships are the most vulnerable. Once upon a time, one merchant boasted in a casino that he was not afraid and that he preferred to spend his credits on the game rather than on protection - after he left the planet, no one saw him again. The fewest incidents were recorded with space rockets and patrol ships.";

const expedition =
  "Lost planet - Apparently there was one more planet in the galaxy - a desert sphere to which the Space Research Agency sent its expedition in search of crystals. Problems began as soon as they landed when the readings started to go crazy and the ship's crew reported strange phenomena in the distance, as if something 'called' them and knew the 'thoughts' of the crew members. The Android, which was supposed to support the crew, suddenly began to speak in a strange language, unknown to anyone, its behavior clearly indicating some foreign interference, like a hacker attack. Communication with everyone was lost shortly thereafter. Some time later, another unexplained phenomenon occurred - the rescue team not only did not find the previous crew, but it turned out that there is no trace of the planet either... To this day, it is not known what really happened then, the Space Research Agency maintains that there was no expedition, and all guesses are galactic legends.";
const texts = [ufo, expedition];

const getRandomText = () => {
  return texts[Math.floor(Math.random() * texts.length)];
};

export default getRandomText;

export const howToPlay = [
  {
    header: "Trade",
    side: "right",
    alt: "A big black neon sign with red lettering that says open. Shop logo.",
    description:
      "What would life be without trading? One of the first store owners on the planet Crion once asked. You can buy some useful items from the trader, which you will need to upgrade the rocket or when you need help with solving the quiz. Due to the increase in competition in recent years, prices in stores are no longer as high as they used to be and goods are more accessible.",
  },
  {
    header: "Gambling",
    side: "left",
    alt: "The big pink neon sign with the word casino. Casino logo.",
    description:
      " Gambling is dangerous, but it can be profitable when taken in reasonable amounts. There are casinos on some of the planets that offer you the chance to get rich quickly or lose your money. Rumor has it that somewhere in the galaxy there is an illegal underground casino where the game is for more than just credits...",
  },
  {
    header: "Mercenaries",
    side: "right",
    alt: "A Shiny black and pink drink neon sign. Bar logo.",
    description:
      "Bars are very popular places in the galaxy. These are places for socializing and exchanging views. In the bars you can hire mercenaries who can be sent on expeditions. Each mercenary has its own characteristics such as level, strength or price. In your inventory, you will have access to hired mercenaries, which you can also dismiss and get a refund of half of the mercenary's price. If you have at least one mercenary, you can send expeditions from which mercenaries can bring back valuable loot. The chance of a successful expedition depends on the chosen difficulty level of the expedition and the strength of the crew sent. The crew sent on the expedition can be attacked, then a fight with the enemy will take place. Depending on the strength of the mercenaries, the result of the fight may be different, the crew may return safe, or a part of the crew may be killed by the enemy. After sending the expedition, you can continue the fun, when the expedition returns, its result will appear on the screen wherever you are.",
  },
  {
    header: "Factory",
    side: "left",
    alt: "A large robot and a space rocket, as well as a night and stars in the background. Factory logo.",
    description:
      " Some planets have factories that are a very important part of the space industry. Here, teams of robot-assisted engineers build rockets, spacecrafts, and components that you can use. For the appropriate amount of credits, the team will be happy to upgrade your rocket, as long as you provide the right amount of resources. A higher rocket level will give you access to more planets and places that will allow you to increase your game progress.",
  },
  {
    header: "Combat",
    side: "right",
    alt: "Several black and menacing looking UFO ships on a yellow background. UFO logo.",
    description:
      "Sometimes you have to face an opponent who seems much stronger - even risking all your belongings. By deciding to fight a UFO, you will not only inquire a favor to the inhabitants of the galaxy, but you can also get rich. But if you lose, you will lose all your inventory. You decide for yourself if it's worth it, even if you lose once, maybe you will finally succeed, there are many enemy ships in the galaxy.",
  },
  {
    header: "Mining",
    side: "left",
    alt: "A large chunk of rock with pink crystal fragments stuck in it. Mine logo.",
    description:
      " The small fee for a one-time entry to the mine is not too high if you take into account what you can get in return if you are lucky. In mines, apart from items that can be easily sold, you can find raw materials for upgrading the rocket.",
  },
  {
    header: "Tests",
    side: "right",
    alt: "A diploma with a blue ribbon. University logo.",
    description:
      "At universities, you can take on various challenges. One of them is to supplement the randomly selected text with appropriate fragments. The professors will be happy to prepare interesting texts, for the performance of which you will receive a prize. If you want, you can prepare your own supplementary text and take the challenge. Let it not be too easy - you will only get rewards for challenges prepared by universities. Another challenge is completing random sentences with appropriate words. The types of sentences are free to choose from as they are categorized according to the English tenses. In the summary you will see correct answers marked in green for correct answer or red in case of incorrect answer. You will only get a reward for correct answers.",
  },
  {
    header: "Quizes",
    side: "left",
    alt: "A thick red book. School logo.",
    description:
      "There is a school on every planet where you can learn something by asking the teacher for specific words. This way you can learn new words. As a test of your knowledge in school, there is the possibility of creating quizzes that involve guessing words with their description - teachers love such puzzles. Solving quizzes is a great way to gain experience and credits. In stores you can buy a 'word' item which will reveal the word for you in the quiz if you run out of ideas.",
  },
  {
    header: "Favorites",
    side: "right",
    alt: "A white-gray satellite.",
    description:
      "After completing the quiz, the words you are interested in can be added to the favorites tab, which you can refer to at any time during the game. You can easily edit the list of favorite words with their definitions at any time.",
  },
];
