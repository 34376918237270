import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gamePauseStatusSwitched, currentPlanetSet } from "../../store/auth";
import { navbarBgChanged } from "../../store/game";

const useChangeGameStatus = (planet) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user.auth);
  const progress = currentUser?.progress;
  const currentPlanet = progress?.currentPlanet;

  useEffect(() => {
    if (currentUser) {
      dispatch(gamePauseStatusSwitched(false));
      dispatch(currentPlanetSet(planet ? planet : currentPlanet));
    }
    return () => {
      dispatch(navbarBgChanged(0));
    };
  }, [currentPlanet, currentUser, dispatch, planet]);
};

export default useChangeGameStatus;
