import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  creditsSubtracted,
  narrationUnlocked,
  dialogueShownAndCompleted,
  vortexUnlocked,
} from "../../store/auth";
import dialogues from "../../utils/dialogues";
import { notify } from "../../store/utils";
import "./answer.css";

const Answer = ({ questionID, showAnswer }) => {
  const {
    inventory: { credits },
    currentPlanet,
  } = useSelector((state) => state.user.auth.currentUser.progress);
  const dispatch = useDispatch();

  const handleStory = (planet) => {
    if (planet === "bathea") {
      if (credits >= 25000) {
        dispatch(creditsSubtracted(25000));
        dispatch(narrationUnlocked("axios"));
        dispatch(
          dialogueShownAndCompleted({
            id: 2,
            planet: "bathea",
            event: "findTheCrew",
          })
        );
        notify("Success!");
      } else {
        notify("Not enough [!]");
      }
    } else if (planet === "axios") {
      dispatch(narrationUnlocked("desertia"));
      dispatch(
        dialogueShownAndCompleted({
          id: 10,
          planet: "axios",
          event: "helpScientists",
        })
      );
      notify("Expedition prepared");
    } else if (planet === "xillon") {
      dispatch(narrationUnlocked("centuria"));
      dispatch(
        dialogueShownAndCompleted({
          id: 3,
          planet: "xillon",
          event: "winAWar",
        })
      );
      dispatch(vortexUnlocked());
      notify("We won!");
    }
  };

  const performASpecialActionIfAvailable = () => {
    const dialogue = dialogues[currentPlanet].find(
      (elem) => elem.id === questionID
    );

    if (dialogue.specialAction && !dialogue.specialAction.completed)
      return (
        <button
          className="button small"
          onClick={() => {
            handleStory(currentPlanet);
            showAnswer();
          }}
        >
          {dialogue.specialAction.title} ({dialogue.specialAction.requirement}
          [!])
        </button>
      );
  };

  return (
    <>
      <li className="dialogue-line">
        {dialogues[currentPlanet].find((elem) => elem.id === questionID).answer}
      </li>
      <li>{performASpecialActionIfAvailable()}</li>
      {showAnswer && (
        <li
          className="visited dialogue-line cursor-pointer"
          onClick={showAnswer}
        >
          Thanks...
        </li>
      )}
    </>
  );
};

export default Answer;

Answer.propTypes = {
  questionID: PropTypes.number,
  showAnswer: PropTypes.func,
};
