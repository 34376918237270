import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { mercenaryFired, mercenaryHired } from "../../store/auth";
import { mercenariesStatusChanged } from "../../store/auth";
import Thumbnail from "./thumbnail";
import "./npcForHireCard.css";
import { notify } from "../../store/utils";

const NpcForHireCard = ({ mercenary }) => {
  const { _id, price, strength, name, lvl } = mercenary;

  const {
    inventory: { credits },
    mercenaries: { hired, sent, selected },
  } = useSelector((state) =>
    state.user.auth.currentUser
      ? state.user.auth.currentUser.progress
      : {
          inventory: { credits: null },
          mercenaries: { hired: [], sent: [], selected: [] },
        }
  );

  const dispatch = useDispatch();

  const renderHireButton = () => {
    return credits >= price ? (
      <button
        onClick={() => dispatch(mercenaryHired({ id: _id, price: price }))}
        className="button small margin-1rem"
      >
        Hire
      </button>
    ) : (
      <button
        onClick={() => notify("Not enough [!]")}
        className="button small margin-1rem"
      >
        Not enough [!]
      </button>
    );
  };

  const renderMercenaryAvatars = () => {
    if (hired.includes(_id) && !selected.includes(_id)) {
      return (
        <Thumbnail
          onClickAction={() =>
            dispatch(
              mercenariesStatusChanged({ idArray: [_id], status: "mark" })
            )
          }
          imageCategory={"mercenaries"}
          image={name}
          alt={"Mercenary portrait"}
        />
      );
    } else if (hired.includes(_id) && selected.includes(_id)) {
      return (
        <Thumbnail
          onClickAction={() =>
            dispatch(
              mercenariesStatusChanged({
                idArray: [_id],
                status: "release",
              })
            )
          }
          imageCategory={"mercenaries"}
          image={"Locked"}
          alt={"Mercenary portrait"}
        />
      );
    } else {
      return (
        <Thumbnail
          onClickAction={
            credits >= price
              ? () => dispatch(mercenaryHired({ id: _id, price: price }))
              : () => notify("Not enough [!]")
          }
          imageCategory={"mercenaries"}
          image={name}
          alt={"Mercenary portrait"}
        />
      );
    }
  };

  return (
    <article
      id="mercenaries-list"
      className="padding-places border carousel-card"
    >
      <header className="fav-title">
        <h4>{name}</h4>
      </header>
      <p className="thumbnail logo-place image fit margin-bottom-0">
        {renderMercenaryAvatars(name)}
      </p>
      <ul className="align-center">
        <li>Lvl: {lvl}</li>
        <li>Price: {price}</li>
        <li>Strength: {strength}</li>
      </ul>
      {!hired.includes(_id) && renderHireButton()}
      {hired.includes(_id) && !sent.includes(_id) && !selected.includes(_id) && (
        <li>
          <button
            onClick={() => dispatch(mercenaryFired({ id: _id, price: price }))}
            className="button small"
          >
            Send back
          </button>
        </li>
      )}
    </article>
  );
};

export default NpcForHireCard;

NpcForHireCard.propTypes = {
  appElement: PropTypes.object,
};
