import React from "react";
import PropTypes from "prop-types";
import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import { favoriteItemAdded, favoriteItemRemoved } from "../../store/auth";
import { notify } from "../../store/utils";
import Icon from "./icon";

const FavoritesButton = ({ word, definition }) => {
  const { favorites } = useSelector(
    (state) => state.user.auth.currentUser.progress
  );
  const dispatch = useDispatch();

  const button = () => {
    if (!favorites.find((elem) => elem.word === word)) {
      return (
        <Icon
          cls={"far fa-star cursor-pointer"}
          onClickAction={() => {
            dispatch(favoriteItemAdded({ word, definition }));
            notify("Added to favorites");
          }}
        />
      );
    } else {
      return (
        <Icon
          cls={"fas fa-star cursor-pointer"}
          onClickAction={() => {
            dispatch(favoriteItemRemoved(word));
            notify("Removed from favorites");
          }}
        />
      );
    }
  };
  return button();
};

export default FavoritesButton;

FavoritesButton.propTypes = {
  word: PropTypes.string,
  definition: PropTypes.string,
};
